import { useTranslation } from 'react-i18next';
import { Link } from "react-router-dom";
import React from 'react';
import ReactDOM from 'react-dom/client';
import ReCAPTCHA from "react-google-recaptcha";

function onChange(value) {
    console.log("Captcha value:", value);
  }

const Contact = () => {
    const { t } = useTranslation();
const [inputs, setInputs] = React.useState({})
const handleChange = (event) => {
    const name = event.target.name
    const value = event.target.value
    setInputs(values => ({...values, [name]: value}))
    }

    return (
    <form onSubmit={(event) => {
        event.preventDefault();
        fetch('https://smtpclient.kcodes.com/text-mail', {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(inputs),
        }).then((data) => console.log(data));
    }}>

        <section className="page-header page-header-xs">
            <div className="container">
                <h1 className="maven">{t("contact.Contact Us")}</h1>
                <ol className="breadcrumb">
                    <li><Link to="/" title="Home">{t("contact.Home")}</Link></li>
                    <li><a href="/contact" title="Contact Us">{t("contact.Contact Us")}</a></li>
                </ol>
            </div>
        </section>

        <section className="contact">
            <div className="container">                 
            <div className="row">
                <div className="col-md-8">
                    <h3 className="maven h4 font-weight-bold color-primary mb-20 pb-20">{t("contact.Sales Enquiry & Technical Support")}</h3>
                    <div id="messages-area"></div>
                    {/*<form action="" method="post" enctype="multipart/form-data">*/}
                        <fieldset>
                            {/*<input type="hidden" name="action" value="contact_send" />*/}
                            <div className="row">
                                <div className="col-md-6">
                                    <label for="contact:name">{t("contact.Person Name")}</label>
                                    <input type="text" className="form-control rad-0 fs-14" name="person" id="person" value={inputs.person || ""} onChange={handleChange} />
                                </div>
                                <div className="col-md-6">
                                    <label for="contact:email">{t("contact.E-Mail")}</label>
                                    <input type="email" className="form-control rad-0 fs-14" name="email" id="email" value={inputs.email || ""} onChange={handleChange} />
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-md-6">
                                    <label for="contact:name">{t("contact.Your Company")} </label>
                                    <input type="text" className="form-control rad-0 fs-14" name="company" id="company" value={inputs.company || ""} onChange={handleChange} />
                                </div>
                                <div className="col-md-6">
                                    <label for="contact:phone">{t("contact.Tel")}</label>
                                    <input type="text" className="form-control rad-0 fs-14" name="tel" id="tel" value={inputs.tel || ""} onChange={handleChange} />
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-md-12">
                                    <label for="contact:subject">{t("contact.Subject")}</label>
                                    <input type="text" className="form-control rad-0 fs-14" name="subject" id="subject" value={inputs.subject || ""} onChange={handleChange} />
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-md-12">
                                    <label for="contact:message">{t("contact.Content")}</label>
                                    <textarea maxlength="10000" rows="5" className="form-control rad-0 fs-14" name="text" id="text" placeholder="Your Message" value={inputs.text || ""} onChange={handleChange}></textarea>
                                </div>
                            </div>

                            <div>
                                <ReCAPTCHA
                                    sitekey="6LdiMpglAAAAAEhRv-V9XfE1TFbpuzjcnMlfYqSQ"
                                    onChange={onChange}
                                />
                            </div>

                        </fieldset>
                        <div className="row">
                            <div className="col-md-12">
                                <button type="submit" className="btn btn-3d btn-info rad-0"><i className="fas fa-check"></i> {t("contact.SEND MESSAGE")}</button>
                            </div>
                        </div>
                    {/*</form>*/}
                    </div>

                <div className="col-md-4">
                    <div className="mb-20">
                        <p><iframe style={{border: '0'}} src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3613.584788965935!2d121.56469641468144!3d25.08205844251794!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3442ac6c8b9ca611%3A0x256b46ffa9ca5a80!2z55uI56K856eR5oqA6IKh5Lu95pyJ6ZmQ5YWs5Y-4!5e0!3m2!1szh-TW!2stw!4v1527648368690" width="100%" height="450" frameborder="0" allowfullscreen="allowfullscreen"></iframe></p>
                    </div>
                    <address className="mb-0">
                        <ul className="list-unstyled mb-0">
                            <li className="footer-sprite">
                                <i className="fas fa-map-signs"></i>
                                {t("contact.Address")}"
                            </li>
                            <li className="footer-sprite">
                                <i className="fas fa-phone"></i>
                                {t("contact.Tel")} : <a href="tel:+886 2 2656 2386">+886 2 2656 2386</a>
                            </li>
                            <li className="footer-sprite">
                                <i className="fas fa-fax"></i>
                                {t("contact.Fax")} : +886 2 2797 6047
                            </li>
                            <li className="footer-sprite">
                                <i className="fas fa-envelope-o"></i>
                                {/*{t("contact.Product")} : <a href="mailto:"></a> <br/>*/}
                                {t("contact.Support")} : <a href="mailto:support@kcodes.com">support@kcodes.com</a>
                            </li>
                        </ul>
                    </address>
                </div>
            </div>
            </div>
        </section>        
    </form>
    
    )
  };
 
  export default Contact;
  ReactDOM.createRoot(document.getElementById("root")).render(<Contact />)  
  
