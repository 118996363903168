import { useTranslation } from 'react-i18next';
function Footer() {
	const { t } = useTranslation();
    return (
        <>
        <footer id="footer" className="bg-bluegreen">
			<div className="container">
				<div className="row">
					<div className="col-md-4">
						<p><img className="footer-logo" src="/images/logo.svg" alt="" width="100%" /></p>
						<div className="company-info">
							<p>{t("Footer.KCodes")}</p>
						</div>
					</div>
					<div className="col-md-4">
						<h2 className="letter-spacing-1 maven">{t("Footer.LINKS")}</h2>
						<ul className="footer-links list-unstyled">
							<li><a href="/" title={t("Footer.Home")}>{t("Footer.Home")}</a></li>
							<li><a href="/about" title={t("Footer.About KCodes")}>{t("Footer.About KCodes")}</a></li>
							<li><a href="/news" title={t("Footer.News")}>{t("Footer.News")}</a></li>
							<li><a href="/product" title={t("Footer.Product")}>{t("Footer.Product")}</a></li>
							<li><a href="/contact" title={t("Footer.Contact Us")}>{t("Footer.Contact Us")}</a></li>
						</ul>
					</div>

					<div className="col-md-4">
						<h2 className="letter-spacing-1 maven">{t("Footer.FIND US")}</h2>
						<address>
							<ul className="list-unstyled">
								<li className="footer-sprite">
									<i className="fas fa-map-signs"></i>
									{t("Footer.Address")}
								</li>
								<li className="footer-sprite">
									<i className="fas fa-phone"></i>
									{t("Footer.Tel")}
								</li>
								<li className="footer-sprite">
									<i className="fas fa-fax"></i>
									{t("Footer.Fax")}
								</li>
								<li className="footer-sprite">
									<i className="fas fa-envelope-o"></i>
									{t("Footer.Support")}
								</li>
							</ul>
						</address>
					</div>
				</div>
			</div>
			<div className="copyright">
				<div className="container">
					<p>2018 &copy; KCodes Corporation. All Rights Reserved.</p>
				</div>
			</div>
		</footer>

        </>
    )
}
export default Footer;