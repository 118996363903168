import { useTranslation } from 'react-i18next';
const AboutMain = () => {
    const { t } = useTranslation();
    return (
      <>
		<section className="page-header page-header-xs">
            <div className="container">
                <h1 className="maven">{t("About.About KCodes")}</h1>
                
            </div>
        </section>

        <section>
            <div className="container">
                <div className="row">
                    <div className="col-lg-9 order-lg-2 order-1">
                        <table style={{width: '100%'}}>
                            <tbody>
                                <tr>
                                    <td>
                                    
                                        <p><span style={{fontSize: '12pt'}}>{t("About.KCodes Corporation was founded in June 2001")}</span></p>
                                        <p><span style={{fontSize: '12pt'}}>{t("About.Moreover")}</span></p>
                                        <p><span style={{fontSize: '12pt'}}>{t("About.Today")}</span></p>
                                        <p>&nbsp;</p>
                                        <p><em><strong><span style={{fontSize: '12pt'}}><span style={{fontSize: '14pt'}}>{t("Team.Chairman Zekai Hsiau,")}</span> </span></strong></em><br /><span style={{fontSize: '12pt'}}>{t("Team.Ph.D. of Computer Scienc, Stanford University")}</span><br /><span style={{fontSize: '12pt'}}>{t("Team.Electrical Engineering, Taiwan University")}</span></p>
                                        <p><span style={{fontSize: '12pt'}}>{t("Team.Dr. Zekai Hsiau was born in Taipei, Taiwan. He received the B.S. degree in Electrical Engineering from National Taiwan University, Taipei, Taiwan in 1988, and the Ph.D. degree in Computer Science from Stanford University, Palo Alto, California, USA 1997.")}</span></p>
                                        <p><span style={{fontSize: '12pt'}}>{t("Team.He served as a Second-Lieutenant Information Officer at the Marine Corp. of Taiwan from 1988 to 1990. He consulted in high-performance computing at Stanford Linear Accelerator Center in 1993. From 1997 to 1999, he was Research Associate at the Department of Electrical Engineering, Stanford University working on high-performance computing algorithms. From 1999 to 2000, he was Technical Advisor to Tailyn Communication Company, Ltd., developing embedded software for communication systems. He is a board member of Cargile Co., Ltd. and Global Mixed-Signal Technology, Inc.")}</span></p>
                                        <p>&nbsp;</p>
                                        <p><em><strong><span style={{fontSize: '14pt'}}>{t("Team.President James Liu,")}</span></strong></em></p>
                                        <p><span style={{fontSize: '12pt'}}>{t("Team.1977 - 2002 PIONEER Electronic (Taiwan) Corp. VP")}</span><br /><span style={{fontSize: '12pt'}}>{t("Team.1998 - 2004 Dong-guan Monetech Corp. (PIONEER group) President")} </span><br /><span style={{fontSize: '12pt'}}>{t("Team.2002 - 2006 Dong-guan MNC Corp. (PIONEER group) Chairman")}</span><br /><span style={{fontSize: '12pt'}}>{t("Team.2002 - 2017 Global Mixed-mode Technology Inc. Independent Supervisor")} </span><br /><span style={{fontSize: '12pt'}}>{t("Team.2011 - KCode Corp. President")}</span></p>
                                    
                                    </td>
                                    
                                </tr>
                            </tbody>
                        </table>   
                    </div>
                </div>
            </div>
        </section>
      </>
    )
  };
  
  export default AboutMain;