import React from "react";
import ReactDOM from "react-dom/client";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Layout from "./Layout.js";
import Home from "./Home.js";
import About from "./About.js";
import AboutMain from "./AboutMain.js";
//import History from "./History.js";
//import Team from "./Team.js";
import News from "./News";
import NewsMain from "./NewsMain.js";
import NewsMain1 from "./NewsMain1.js";
//import LatestNews from "./LatestNews.js";
import Page1 from './Page1.js';
import Page2 from './Page2.js';
import Page3 from './Page3.js';
import Page4 from './Page4.js';
import Page5 from './Page5.js';
import Page6 from './Page6.js';
import Page7 from './Page7.js';
import Page8 from './Page8.js';
import Page9 from './Page9.js';
import Page10 from './Page10.js';
import Page11 from './Page11.js';
import Page12 from './Page12.js';
import Page13 from './Page13.js';
import Page14 from './Page14.js';
import Page15 from './Page15.js';
import Page16 from './Page16.js';
import Page17 from './Page17.js';
import Page18 from './Page18.js';
import Product from "./Product.js";
import ProductMain from "./ProductMain.js";
import Wired from "./Wired.js";
import KC601 from "./KC601.js";
import KC611 from "./KC611.js";
import KC612 from "./KC612.js";
import EX200 from "./EX200.js";
import Wireless from "./Wireless.js";
import KC601n from "./KC601n.js";
//import KC604n from "./KC604n.js";
import KC612ac from "./KC612ac.js";
import WB112ac from "./WB112ac.js";
import KC622n from "./KC622n.js";
import MT7933IoTModule from "./MT7933IoTModule.js";
import SoftwareTechnology from "./SoftwareTechnology";
import NetUSB from "./NetUSB.js";
import SMB from "./SMB.js";
import DMS from "./DMS.js";
import DMR from "./DMR.js";
import ODMOEM from "./ODMOEM";
import CustomizedProduct from "./CustomizedProduct";
import EPAPER from "./EPAPER";
import Contact from "./Contact.js";
import { Suspense } from "react";
import i18n from "./i18n.js";

export default function App() {
  return (
    <Suspense fallback="等我一下">
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<Layout />}>
          <Route index element={<Home />} /> 
          <Route path="/Page1" element={<Page1 />} />
          <Route path="/Page2" element={<Page2 />} />
          <Route path="/Page3" element={<Page3 />} />
          <Route path="/Page4" element={<Page4/>} />
          <Route path="/Page5" element={<Page5/>} />
          <Route path="/Page6" element={<Page6/>} />
          <Route path="/Page7" element={<Page7/>} />
          <Route path="/Page8" element={<Page8/>} />
          <Route path="/Page9" element={<Page9/>} />
          <Route path="/Page10" element={<Page10/>} />
          <Route path="/Page11" element={<Page11/>} />
          <Route path="/Page12" element={<Page12/>} />
          <Route path="/Page13" element={<Page13/>} />
          <Route path="/Page14" element={<Page14/>} />
          <Route path="/Page15" element={<Page15/>} />
          <Route path="/Page16" element={<Page16/>} />
          <Route path="/Page17" element={<Page17/>} />
          <Route path="/Page18" element={<Page18/>} />
          <Route path="NewsMain1" element={<NewsMain1/>} />   

          <Route path="about" element={<About />} >
            <Route index element={<AboutMain />} />
            
          </Route>

          <Route path="news" element={<News />} >
            <Route index element={<NewsMain />} />
          
          </Route>
          
          <Route path="product" element={<Product />} > 
            <Route index element={<ProductMain />} />
            <Route path="wired"element={<Wired />} />
            <Route path="kc601"element={<KC601 />} />
            <Route path="kc611"element={<KC611 />} />
            <Route path="kc612"element={<KC612 />} />
            <Route path="ex200"element={<EX200 />} />
            <Route path="wireless" element={<Wireless />} />  
            <Route path="kc601n"element={<KC601n />} />
            {/*<Route path="kc604n"element={<KC604n />} />*/}
            <Route path="kc612ac"element={<KC612ac />} />
            <Route path="wb112ac"element={<WB112ac />} />
            <Route path="kc622n"element={<KC622n />} />
            <Route path="mt7933iotmodule" element={<MT7933IoTModule />} />
            <Route path="softwaretechnology" element={<SoftwareTechnology />} /> 
            <Route path="netusb"element={<NetUSB />} />
            <Route path="smb"element={<SMB />} />
            <Route path="dms"element={<DMS />} />
            <Route path="dmr"element={<DMR />} />
            <Route path="odmoem" element={<ODMOEM />} />
            <Route path="customizedproduct" element={<CustomizedProduct />} />
            <Route path="epaper" element={<EPAPER />} />
          </Route>

          <Route path="contact" element={<Contact />} />            
          </Route>
        </Routes>
      </BrowserRouter>
    </Suspense>
  );
}

ReactDOM.createRoot(document.getElementById('wrapper')).render(
  <React.StrictMode>
    <App />
  </React.StrictMode>
);