import {Outlet} from "react-router-dom";
import Footer from './Footer.js'
import TopBar from './TopBar.js'
import Header from './Header.js'


const Layout = () => {
  return (
    <>
      <TopBar />
      <Header />
      <div id="messages" class="text-center"></div>
      <Outlet />
      <Footer />
    </>
  )
};

export default Layout;