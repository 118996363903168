import { useTranslation } from 'react-i18next';

const lngs = {
	en: { nativeName: 'ENGLISH' },
	tw: { nativeName: '繁體中文'},
	jp: { nativeName: '日本語'}
};

function TopBar() {
	const { t, i18n } = useTranslation();
    return (
        <>
	<div id="topBar">
		<div className="container">
			<ul className="top-links list-inline">                       
				<li  className="active" >
					{/*<a href="https://www.kcodes.com"><img src="images/en.png" className="pr-5" />English</a>*/}
					<button key="en" style={{ fontWeight: i18n.resolvedLanguage === "en" ? 'bold' : 'normal' ,color:'#ffff',height:'30px' }} type="submit" onClick={() => i18n.changeLanguage("en")}>
						<img src="/images/en.png" alt="English" className="pr-5" />{lngs.en.nativeName}
					</button>
					
				</li>					
				<li >
					{/*<a href="https://www.kcodes.com/tw"><img src="images/tw.png" className="pr-5" />繁體中文</a>*/}
					<button key="tw" style={{ fontWeight: i18n.resolvedLanguage === "tw" ? 'bold' : 'normal',color:'#ffff' }} type="submit" onClick={() => i18n.changeLanguage("tw")}>
					&emsp;<img src="/images/tw.png" alt="Traditional Chinese" className="pr-5" />{lngs.tw.nativeName}
					</button>
				</li>					
				<li >
					{/*<a href="https://www.kcodes.com/jp"><img src="images/jp.png" className="pr-5" />日本語</a>*/}
					<button key="jp" style={{ fontWeight: i18n.resolvedLanguage === "jp" ? 'bold' : 'normal',color:'#ffff' }} type="submit" onClick={() => i18n.changeLanguage("jp")} >
					&emsp;<img src="/images/jp.png" alt="Japanese" className="pr-5" />{lngs.jp.nativeName}
					</button>
				</li>
			</ul>
			<div className="top-info float-right d-none d-xl-block">
				<ul className="list-unstyled list-inline mb-0">
					<li>
						<i className="fas fa-phone"></i>
						{t("TopBar.Tel")} : <a href="tel:+886 2 2656 2386">+886 2 2656 2386</a>
					</li>
					<li>
						<i className="fas fa-envelope-o"></i>
						{t("TopBar.Support")} : <a href="mailto:support@kcodes.com">support@kcodes.com</a>
					</li>
					
				</ul>
			</div>
		</div>
	</div>
        </>
    )
	
}
export default TopBar;