import { useTranslation } from 'react-i18next';
import { Link } from "react-router-dom";
const SoftwareTechnology = () => {
    const { t } = useTranslation();
    return (
      <>
        <section className="page-header page-header-xs">
            <div className="container">
                <h1 className="maven">{t("Product.Software Technology")}</h1>
                <ol className="breadcrumb">
                    <li><Link to="/" title={t("Product.Home")}>{t("Product.Home")}</Link></li>
                    <li><Link to="/product" title={t("Product.Product")}>{t("Product.Product")}</Link></li>
                    <li><Link to="/product/softwaretechnology" title={t("Product.Software Technology")}>{t("Product.Software Technology")}</Link></li>
                </ol>
            </div>
        </section>

        <section>
            <div className="container">
                <form name="searchform" id="searchform" method="get">
                    <div id="portfolio" className="portfolio-gutter portfolio-title-over">
                        <div className="text-center">
                            <ul className="nav nav-pills mix-filter mb-30 text-center">
                                <li data-filter="all" className="filter active"><Link to="/product" title={t("Product.All")}>{t("Product.All")}</Link></li>
                                <li data-filter="group-3" className="filter"><Link to="/product/wired" title={t("Product.Wired")}>{t("Product.Wired")}</Link></li>
                                <li data-filter="group-4" className="filter"><Link to="/product/wireless" title={t("Product.Wireless")}>{t("Product.Wireless")}</Link></li>
                                <li data-filter="group-1" className="filter"><Link to="/product/softwaretechnology" title={t("Product.Software Technology")}>{t("Product.Software Technology")}</Link></li>
                                <li data-filter="group-2" className="filter"><Link to="/product/odmoem" title={t("Product.ODM & OEM")}>{t("Product.ODM & OEM")}</Link></li>
                            </ul>
                        </div>
                        {/*<div className="row mix-grid">*/}
                        <div className="row">
                            <div className="col-md-4 col-sm-6 mix mb-0 group-1 fadeInRight">
                                <div className="item-box">
                                    <figure>
                                        <span className="item-hover">
                                            <span className="overlay dark-5"></span>
                                            <span className="inner">
                                                <Link className="ico-rounded lightbox" to="/images/product/pic/netusb.jpg" data-plugin-options='{"type":"image"}'>
                                                    <span className="fas fa-plus fs-20 rad-0"></span>
                                                </Link>
                                                <Link className="ico-rounded" to="/product/netusb">
                                                    <span className="fas fa-link fs-20 rad-0"></span>
                                                </Link>
                                            </span>
                                        </span>
                                        <div className="item-box-overlay-title">
                                            <h3><Link to="/product/netusb" title="NetUSB (USB over IP)">NetUSB (USB over IP)</Link></h3>
                                            <ul className="list-inline categories m-0">
                                                <li><a href="javascript:void(0);" className="uppercase">{t("Product.Software Technology")}</a></li>
                                            </ul>
                                        </div>
                                        {/*<div className="owl-carousel buttons-autohide controlls-over m-0" data-plugin-options='{"singleItem": true, "autoPlay": 4000, "navigation": false, "pagination": true, "transitionStyle":"goDown"}'>*/}
                                        <div className="productanimation">
                                            <div>
                                                <img className="img-fluid" src="/images/product/pic/tnetusb.jpg" width="600" height="399" alt="NetUSB (USB over IP)" />
                                            </div>
                                        </div>
                                    </figure>
                                </div>
                            </div>
                            <div className="col-md-4 col-sm-6 mix mb-0 group-1 fadeInLeft">
                                <div className="item-box">
                                    <figure>
                                        <span className="item-hover">
                                            <span className="overlay dark-5"></span>
                                            <span className="inner">
                                                <Link className="ico-rounded lightbox" to="/images/product/pic/SMB2.jpg" data-plugin-options='{"type":"image"}'>
                                                    <span className="fas fa-plus fs-20 rad-0"></span>
                                                </Link>
                                                <Link className="ico-rounded" to="/product/smb">
                                                    <span className="fas fa-link fs-20 rad-0"></span>
                                                </Link>
                                            </span>
                                        </span>
                                        <div className="item-box-overlay-title">
                                            <h3><Link to="/product/smb" title="SMB">SMB</Link></h3>
                                            <ul className="list-inline categories m-0">
                                                <li><a href="javascript:void(0);" className="uppercase">{t("Product.Software Technology")}</a></li>
                                            </ul>
                                        </div>
                                        {/*<div className="owl-carousel buttons-autohide controlls-over m-0" data-plugin-options='{"singleItem": true, "autoPlay": 4000, "navigation": false, "pagination": true, "transitionStyle":"goDown"}'>*/}
                                        <div className="productanimation">
                                            <div>
                                                <img className="img-fluid" src="/images/product/pic/tSMB2.jpg" width="600" height="399" alt="SMB" />
                                            </div>
                                        </div>
                                    </figure>
                                </div>
                            </div>
                            <div className="col-md-4 col-sm-6 mix mb-0 group-1 fadeInLeft">
                                <div className="item-box">
                                    <figure>
                                        <span className="item-hover">
                                            <span className="overlay dark-5"></span>
                                            <span className="inner">
                                                <Link className="ico-rounded lightbox" to="/images/product/pic/DMS2.jpg" data-plugin-options='{"type":"image"}'>
                                                    <span className="fas fa-plus fs-20 rad-0"></span>
                                                </Link>
                                                <Link className="ico-rounded" to="/product/dms">
                                                    <span className="fas fa-link fs-20 rad-0"></span>
                                                </Link>
                                            </span>
                                        </span>
                                        <div className="item-box-overlay-title">
                                            <h3><Link to="/product/dms" title="DMS">DMS</Link></h3>
                                            <ul className="list-inline categories m-0">
                                                <li><a href="javascript:void(0);" className="uppercase">{t("Product.Software Technology")}</a></li>
                                            </ul>
                                        </div>
                                        {/*<div className="owl-carousel buttons-autohide controlls-over m-0" data-plugin-options='{"singleItem": true, "autoPlay": 4000, "navigation": false, "pagination": true, "transitionStyle":"goDown"}'>*/}
                                        <div className="productanimation">
                                            <div>
                                                <img className="img-fluid" src="/images/product/pic/tDMS2.jpg" width="600" height="399" alt="DMS" />
                                            </div>
                                        </div>
                                    </figure>
                                </div>
                            </div>
                            <div className="col-md-4 col-sm-6 mix mb-0 group-1 fadeInRight">
                                <div className="item-box">
                                    <figure>
                                        <span className="item-hover">
                                            <span className="overlay dark-5"></span>
                                            <span className="inner">
                                                <Link className="ico-rounded lightbox" to="/images/product/pic/DMR2.jpg" data-plugin-options='{"type":"image"}'>
                                                    <span className="fas fa-plus fs-20 rad-0"></span>
                                                </Link>
                                                <Link className="ico-rounded" to="/product/dmr">
                                                    <span className="fas fa-link fs-20 rad-0"></span>
                                                </Link>
                                            </span>
                                        </span>
                                        <div className="item-box-overlay-title">
                                            <h3><Link to="/product/dmr" title="DMR">DMR</Link></h3>
                                            <ul className="list-inline categories m-0">
                                                <li><a href="javascript:void(0);" className="uppercase">{t("Product.Software Technology")}</a></li>
                                            </ul>
                                        </div>
                                        {/*<div className="owl-carousel buttons-autohide controlls-over m-0" data-plugin-options='{"singleItem": true, "autoPlay": 4000, "navigation": false, "pagination": true, "transitionStyle":"goDown"}'>*/}
                                        <div className="productanimation">
                                            <div>
                                                <img src="/images/product/pic/tDMR2.jpg" width="600" height="399" alt="DMR" />
                                            </div>
                                        </div>
                                    </figure>
                                </div>
                            </div>
                        </div>
                    </div>
                </form>
            </div>
        </section>
      </>
    )
  };
  
  export default SoftwareTechnology;