import { useTranslation } from 'react-i18next';
import { Link } from "react-router-dom";
const  NewsMain= () => {
    const { t } = useTranslation();
    return (
      <>
		<section className="page-header page-header-xs">
	        <div className="container">
                <h1 className="maven">{t("NewsMain.News")}</h1>
                
            </div>
        </section>

        <section>
            <div className="container">
                <form name="searchform" id="searchform" method="get">
                    <div id="portfolio" className="portfolio-gutter portfolio-title-over ls-overflow-visible">
                        <div className="row mix-grid11">


                            <div className="blog-post-item col-md-12 mb-20 border-bottom-0 pb-0 mix group-1">
                                <div className="bg-white p-20 box-shadow1 btm-linebg">
                                    <h4 className="mb-10"><Link to="/Page18" title={t("NewsMain.Our new software technology")} className="color-primary color-hover-dark">{t("NewsMain.Our new software technology")}</Link></h4>
                                        <ul className="blog-post-info list-inline mb-10 pb-0">
                                            <li>
                                                <i className="fas fa-clock-o"></i>
                                                <span className="uppercase fs-12">Nov 27, 2023</span>
                                            </li>
                                            <li>
                                                <i className="fas fa-tag"></i>
                                                <span className="uppercase fs-12">{t("NewsMain.Latest News")}</span>
                                            </li>
                                        </ul>
                                        <p className="mb-20">...</p>
                                        <Link to="/Page18" title={t("NewsMain.Read More")} className="btn btn-primary btn-sm rad-0">{t("NewsMain.Read More")}<i className="fas fa-angle-right pl-10"></i></Link>
                                </div>
                            </div>


                            <div className="blog-post-item col-md-12 mb-20 border-bottom-0 pb-0 mix group-1">
                                <div className="bg-white p-20 box-shadow1 btm-linebg">
                                    <h4 className="mb-10"><Link to="/Page17" title={t("NewsMain.KCodes is your best ePaper partner. We are now providing various customized e-paper solutions.")} className="color-primary color-hover-dark">{t("NewsMain.KCodes is your best ePaper partner. We are now providing various customized e-paper solutions.")}</Link></h4>
                                        <ul className="blog-post-info list-inline mb-10 pb-0">
                                            <li>
                                                <i className="fas fa-clock-o"></i>
                                                <span className="uppercase fs-12">MAY 30, 2023</span>
                                            </li>
                                            <li>
                                                <i className="fas fa-tag"></i>
                                                <span className="uppercase fs-12">{t("NewsMain.Latest News")}</span>
                                            </li>
                                        </ul>
                                        <p className="mb-20">...</p>
                                        <Link to="/Page17" title={t("NewsMain.Read More")} className="btn btn-primary btn-sm rad-0">{t("NewsMain.Read More")}<i className="fas fa-angle-right pl-10"></i></Link>
                                </div>
                            </div>

                            <div className="blog-post-item col-md-12 mb-20 border-bottom-0 pb-0 mix group-1">
                                <div className="bg-white p-20 box-shadow1 btm-linebg">
                                    <h4 className="mb-10"><Link to="/Page16" title={t("NewsMain.Your best gaming partner! How about our Light sync solution.")} className="color-primary color-hover-dark">{t("NewsMain.Your best gaming partner! How about our Light sync solution.")}</Link></h4>
                                        <ul className="blog-post-info list-inline mb-10 pb-0">
                                            <li>
                                                <i className="fas fa-clock-o"></i>
                                                <span className="uppercase fs-12">MAY 31, 2022</span>
                                            </li>
                                            <li>
                                                <i className="fas fa-tag"></i>
                                                <span className="uppercase fs-12">{t("NewsMain.Latest News")}</span>
                                            </li>
                                        </ul>
                                        <p className="mb-20">...</p>
                                        <Link to="/Page16" title={t("NewsMain.Read More")} className="btn btn-primary btn-sm rad-0">{t("NewsMain.Read More")}<i className="fas fa-angle-right pl-10"></i></Link>
                                </div>
                            </div>

                            <div className="blog-post-item col-md-12 mb-20 border-bottom-0 pb-0 mix group-1">
                                <div className="bg-white p-20 box-shadow1 btm-linebg">
                                    <h4 className="mb-10"><Link to="/Page15" title={t("NewsMain.KCodes has launched a USB 3.0 extender solution")} className="color-primary color-hover-dark">{t("NewsMain.KCodes has launched a USB 3.0 extender solution")}</Link></h4>
                                        <ul className="blog-post-info list-inline mb-10 pb-0">
                                            <li>
                                                <i className="fas fa-clock-o"></i>
                                                <span className="uppercase fs-12">APR 08, 2019</span>
                                            </li>
                                            <li>
                                                <i className="fas fa-tag"></i>
                                                <span className="uppercase fs-12">{t("NewsMain.Latest News")}</span>
                                            </li>
                                        </ul>
                                        <p className="mb-20">...</p>
                                        <Link to="/Page15" title={t("NewsMain.Read More")} className="btn btn-primary btn-sm rad-0">{t("NewsMain.Read More")}<i className="fas fa-angle-right pl-10"></i></Link>
                                </div>
                            </div>


                            <div className="blog-post-item col-md-12 mb-20 border-bottom-0 pb-0 mix group-1">
                                <div className="bg-white p-20 box-shadow1 btm-linebg">
                                    <h4 className="mb-10"><Link to="/Page1" title={t("NewsMain.KCodes launched Wi-Fi Bridge solution")} className="color-primary color-hover-dark">{t("NewsMain.KCodes launched Wi-Fi Bridge solution")}</Link></h4>
                                        <ul className="blog-post-info list-inline mb-10 pb-0">
                                            <li>
                                                <i className="fas fa-clock-o"></i>
                                                <span className="uppercase fs-12">Jan 10, 2019</span>
                                            </li>
                                            <li>
                                                <i className="fas fa-tag"></i>
                                                <span className="uppercase fs-12">{t("NewsMain.Latest News")}</span>
                                            </li>
                                        </ul>
                                        <p className="mb-20">...</p>
                                        <Link to="/Page1" title={t("NewsMain.Read More")} className="btn btn-primary btn-sm rad-0">{t("NewsMain.Read More")}<i className="fas fa-angle-right pl-10"></i></Link>
                                </div>
                            </div>
                            
                            <div className="blog-post-item col-md-12 mb-20 border-bottom-0 pb-0 mix group-1">
                                <div className="bg-white p-20 box-shadow1 btm-linebg">
                                    <h4 className="mb-10"><Link to="/Page2" title={t("NewsMain.KCodes launched USB 2.0 extender solution")} className="color-primary color-hover-dark">{t("NewsMain.KCodes launched USB 2.0 extender solution")}</Link></h4>
                                    <ul className="blog-post-info list-inline mb-10 pb-0">
                                        <li>
                                            <i className="fas fa-clock-o"></i>
                                            <span className="uppercase fs-12">Nov 01, 2018</span>
                                        </li>
                                        <li>
                                            <i className="fas fa-tag"></i>
                                            <span className="uppercase fs-12">{t("NewsMain.Latest News")}</span>
                                        </li>
                                    </ul>
                                    <p className="mb-20">...</p>
                                    <Link to="/Page2" title={t("NewsMain.Read More")} className="btn btn-primary btn-sm rad-0">{t("NewsMain.Read More")}<i className="fas fa-angle-right pl-10"></i></Link>
                                </div>
                            </div>
                            <div className="blog-post-item col-md-12 mb-20 border-bottom-0 pb-0 mix group-1">
                                <div className="bg-white p-20 box-shadow1 btm-linebg">
                                    <h4 className="mb-10"><Link to="/Page3" title={t("NewsMain.Welcome to visit our booth at COMPUTEX TAIPEI 2019,  No.P0126, Nangang Hall 2, 1F")} className="color-primary color-hover-dark">{t("NewsMain.Welcome to visit our booth at COMPUTEX TAIPEI 2019,  No.P0126, Nangang Hall 2, 1F")}</Link></h4>
                                    <ul className="blog-post-info list-inline mb-10 pb-0">
                                        <li>
                                            <i className="fas fa-clock-o"></i>
                                            <span className="uppercase fs-12">Dec 20, 2018</span>
                                        </li>
                                        <li>
                                            <i className="fas fa-tag"></i>
                                            <span className="uppercase fs-12">{t("NewsMain.Latest News")}</span>
                                        </li>
                                    </ul>
                                    <p className="mb-20">...</p>
                                    <Link to="/Page3" title={t("NewsMain.Read More")} className="btn btn-primary btn-sm rad-0">{t("NewsMain.Read More")}<i className="fas fa-angle-right pl-10"></i></Link>
                                </div>
                            </div>
                            <div className="blog-post-item col-md-12 mb-20 border-bottom-0 pb-0 mix group-1">
                                <div className="bg-white p-20 box-shadow1 btm-linebg">
                                    <h4 className="mb-10"><Link to="/Page4" title={t("NewsMain.KCodes launched USB3.0 Device Server solutions")} className="color-primary color-hover-dark">{t("NewsMain.KCodes launched USB3.0 Device Server solutions")}</Link></h4>
                                    <ul className="blog-post-info list-inline mb-10 pb-0">
                                        <li>
                                            <i className="fas fa-clock-o"></i>
                                            <span className="uppercase fs-12">Jan 15, 2018</span>
                                        </li>
                                        <li>
                                            <i className="fas fa-tag"></i>
                                            <span className="uppercase fs-12">{t("NewsMain.Latest News")}</span>
                                        </li>
                                    </ul>
                                    <p className="mb-20">...</p>
                                    <Link to="/Page4" title={t("NewsMain.Read More")} className="btn btn-primary btn-sm rad-0">{t("NewsMain.Read More")}<i className="fas fa-angle-right pl-10"></i></Link>
                                </div>
                            </div>
                            <div className="blog-post-item col-md-12 mb-20 border-bottom-0 pb-0 mix group-1">
                                <div className="bg-white p-20 box-shadow1 btm-linebg">
                                    <h4 className="mb-10"><Link to="/Page5" title={t("NewsMain.Welcome to our booth at COMPUTEX TAIPEI 2018,  No. M1003, Nangang Hall 1, 4F")} className="color-primary color-hover-dark">{t("NewsMain.Welcome to our booth at COMPUTEX TAIPEI 2018,  No. M1003, Nangang Hall 1, 4F")}</Link></h4>
                                    <ul className="blog-post-info list-inline mb-10 pb-0">
                                        <li>
                                            <i className="fas fa-clock-o"></i>
                                            <span className="uppercase fs-12">Nov 30, 2017</span>
                                        </li>
                                        <li>
                                            <i className="fas fa-tag"></i>
                                            <span className="uppercase fs-12">{t("NewsMain.Latest News")}</span>
                                        </li>
                                    </ul>
                                    <p className="mb-20">...</p>
                                    <Link to="/Page5" title={t("NewsMain.Read More")} className="btn btn-primary btn-sm rad-0">{t("NewsMain.Read More")}<i className="fas fa-angle-right pl-10"></i></Link>
                                </div>
                            </div>
                            <div className="blog-post-item col-md-12 mb-20 border-bottom-0 pb-0 mix group-1">
                                <div className="bg-white p-20 box-shadow1 btm-linebg">
                                    <h4 className="mb-10"><Link to="/Page6" title={t("NewsMain.KCodes obtained Apple MFi developer certification")} className="color-primary color-hover-dark">{t("NewsMain.KCodes obtained Apple MFi developer certification")}</Link></h4>
                                    <ul className="blog-post-info list-inline mb-10 pb-0">
                                        <li>
                                            <i className="fas fa-clock-o"></i>
                                            <span className="uppercase fs-12">Oct 02, 2017</span>
                                        </li>
                                        <li>
                                            <i className="fas fa-tag"></i>
                                            <span className="uppercase fs-12">{t("NewsMain.Latest News")}</span>
                                        </li>
                                    </ul>
                                    <p className="mb-20">...</p>
                                    <Link to="/Page6" title={t("NewsMain.Read More")} className="btn btn-primary btn-sm rad-0">{t("NewsMain.Read More")}<i className="fas fa-angle-right pl-10"></i></Link>
                                </div>
                            </div>

                            <div className="blog-post-item col-md-12 mb-20 border-bottom-0 pb-0 mix group-1">
                                <div className="bg-white p-20 box-shadow1 btm-linebg">
                                    <h4 className="mb-10"><Link to="/Page7" title={t("NewsMain.KCodes licensed over 30 million units SW Royalty to Router, NAS products")} className="color-primary color-hover-dark">{t("NewsMain.KCodes licensed over 30 million units SW Royalty to Router, NAS products")}</Link></h4>
                                    <ul className="blog-post-info list-inline mb-10 pb-0">
                                        <li>
                                            <i className="fas fa-clock-o"></i>
                                            <span className="uppercase fs-12">Jan 10, 2017</span>
                                        </li>
                                        <li>
                                            <i className="fas fa-tag"></i>
                                            <span className="uppercase fs-12">{t("NewsMain.Latest News")}</span>
                                        </li>
                                    </ul>
                                    <p className="mb-20">...</p>
                                    <Link to="/Page7" title={t("NewsMain.Read More")} className="btn btn-primary btn-sm rad-0">{t("NewsMain.Read More")}<i className="fas fa-angle-right pl-10"></i></Link>
                                </div>
                            </div>


 
                        </div>
                    </div>

                    <div className="text-center">
                        <ul className="pagination">	
                        <li className="page-item active"><Link to="/News" className="page-link">1</Link></li>
	                    <li className="page-item"><Link to="/NewsMain1" className="page-submit page-link" data-value="2">2</Link></li>
                        </ul>
                    </div>
                </form>
            </div>
        </section>      
      </>
    )
  };
  
  export default NewsMain;