import { useTranslation } from 'react-i18next';
import { Link } from "react-router-dom";
const Wired = () => {
    const { t } = useTranslation();
    return (
      <>
        <section className="page-header page-header-xs">
        <div className="container">
            <h1 className="maven">{t("Product.Wired")}</h1>
            <ol className="breadcrumb">
                <li><Link to="/" title={t("Product.Home")}>{t("Product.Home")}</Link></li>
                <li><Link to="/product" title={t("Product.Product")}>{t("Product.Product")}</Link></li>
                <li><Link to="/product/wired" title={t("Product.Wired")}>{t("Product.Wired")}</Link></li>
            </ol>
        </div>
        </section>

        <section>
            <div className="container">
                <form name="searchform" id="searchform" method="get">
                    <div id="portfolio" className="portfolio-gutter portfolio-title-over">
                        <div className="text-center">
                            <ul className="nav nav-pills mix-filter mb-30 text-center">
                                <li data-filter="all" className="filter active"><Link to="/product" title={t("Product.All")}>{t("Product.All")}</Link></li>
                                <li data-filter="group-3" className="filter"><Link to="/product/wired" title={t("Product.Wired")}>{t("Product.Wired")}</Link></li>
                                <li data-filter="group-4" className="filter"><Link to="/product/wireless" title={t("Product.Wireless")}>{t("Product.Wireless")}</Link></li>
                                <li data-filter="group-1" className="filter"><Link to="/product/softwaretechnology" title={t("Product.Software Technology")}>{t("Product.Software Technology")}</Link></li>
                                <li data-filter="group-2" className="filter"><Link to="/product/odmoem" title={t("Product.ODM & OEM")}>{t("Product.ODM & OEM")}</Link></li>
                            </ul>
                        </div>
                        {/*<div className="row mix-grid">*/}
                        <div className="row">
                            <div className="col-md-4 col-sm-6 mix mb-0 group-3 fadeInRight">
                                <div className="item-box">
                                    <figure>
                                        <span className="item-hover">
                                        <span className="overlay dark-5"></span>
                                            <span className="inner">
                                                <a className="ico-rounded lightbox" href="/images/product/611/IMG_1265_1.jpg" data-plugin-options='{"type":"image"}'>
                                                    <span className="fas fa-plus fs-20 rad-0"></span>
                                                </a>
                                                <a className="ico-rounded" href="/product/kc601">
                                                    <span className="fas fa-link fs-20 rad-0"></span>
                                                </a>
                                            </span>
                                        </span>
                                        <div className="item-box-overlay-title">
                                            <h3><Link to="/product/kc601" title="KC601">KC601</Link></h3>
                                            <ul className="list-inline categories m-0">
                                            <li><a href="javascript:void(0);" className="uppercase">{t("Product.Wired")}</a></li>
                                            </ul>
                                        </div>
                                        {/*<div className="owl-carousel buttons-autohide controlls-over m-0" data-plugin-options='{"singleItem": true, "autoPlay": 4000, "navigation": false, "pagination": true, "transitionStyle":"goDown"}'>*/}
                                        <div id="carouselIndicators-kc601" className="carousel" data-bs-ride="carousel">
                                            <div className="carousel-indicators member-4">
                                                <button type="button" data-bs-target="#carouselIndicators-kc601" data-bs-slide-to="0" className="active" aria-current="true" aria-label="Slide 1" style={{width:'20px', height:'6px'}}></button>
                                                <button type="button" data-bs-target="#carouselIndicators-kc601" data-bs-slide-to="1" aria-label="Slide 2" style={{width:'20px', height:'6px'}}></button>
                                                <button type="button" data-bs-target="#carouselIndicators-kc601" data-bs-slide-to="2" aria-label="Slide 3" style={{width:'20px', height:'6px'}}></button>
                                                <button type="button" data-bs-target="#carouselIndicators-kc601" data-bs-slide-to="3" aria-label="Slide 4" style={{width:'20px', height:'6px'}}></button>
                                            </div>

                                            <div className="carousel-inner">
                                                <div className="carousel-item active">
                                                    <img className="productanimation" src="/images/product/611/tIMG_1265_1.jpg" width="600" height="399" alt="KC601n" />
                                                </div>
                                                <div className="carousel-item">
                                                    <img className="productanimation" src="/images/product/601n/tIMG_1227_2.jpg" width="600" height="399" alt="KC601" />
                                                </div>
                                                <div className="carousel-item">
                                                    <img className="productanimation" src="/images/product/611/tIMG_1261_1.jpg" width="600" height="399" alt="KC601" />
                                                </div>
                                                <div className="carousel-item">
                                                    <img className="productanimation" src="/images/product/611/tIMG_9896_1.jpg" width="600" height="399" alt="KC601" />
                                                </div>
                                            </div>
                                        </div>
                                    </figure>
                                </div>
                            </div>
                            <div className="col-md-4 col-sm-6 mix mb-0 group-3 fadeInUpLeft">
                                <div className="item-box">
                                    <figure>
                                        <span className="item-hover">
                                            <span className="overlay dark-5"></span>
                                            <span className="inner">
                                                <a className="ico-rounded lightbox" href="/images/product/611/IMG_1267_1.jpg" data-plugin-options='{"type":"image"}'>
                                                    <span className="fas fa-plus fs-20 rad-0"></span>
                                                </a>
                                                <a className="ico-rounded" href="/product/kc611">
                                                    <span className="fas fa-link fs-20 rad-0"></span>
                                                </a>
                                            </span>
                                        </span>
                                        <div className="item-box-overlay-title">
                                            <h3><Link to="/product/kc611" title="KC611">KC611</Link></h3>
                                            <ul className="list-inline categories m-0">
                                            <li><a href="javascript:void(0);" className="uppercase">{t("Product.Wired")}</a></li>
                                            </ul>
                                        </div>
                                        {/*<div className="owl-carousel buttons-autohide controlls-over m-0" data-plugin-options='{"singleItem": true, "autoPlay": 4000, "navigation": false, "pagination": true, "transitionStyle":"goDown"}'>*/}
                                        <div id="carouselIndicators-kc611" className="carousel" data-bs-ride="carousel">
                                            <div className="carousel-indicators member-5">
                                                <button type="button" data-bs-target="#carouselIndicators-kc611" data-bs-slide-to="0" className="active" aria-current="true" aria-label="Slide 1" style={{width:'20px', height:'6px'}}></button>
                                                <button type="button" data-bs-target="#carouselIndicators-kc611" data-bs-slide-to="1" aria-label="Slide 2" style={{width:'20px', height:'6px'}}></button>
                                                <button type="button" data-bs-target="#carouselIndicators-kc611" data-bs-slide-to="2" aria-label="Slide 3" style={{width:'20px', height:'6px'}}></button>
                                                <button type="button" data-bs-target="#carouselIndicators-kc611" data-bs-slide-to="3" aria-label="Slide 4" style={{width:'20px', height:'6px'}}></button>
                                                <button type="button" data-bs-target="#carouselIndicators-kc611" data-bs-slide-to="4" aria-label="Slide 5" style={{width:'20px', height:'6px'}}></button>
                                            </div>

                                            <div className="carousel-inner">
                                                <div className="carousel-item active">
                                                    <img className="productanimation" src="/images/product/611/tIMG_1267_1.jpg" width="600" height="399" alt="KC611" />
                                                </div>
                                                <div className="carousel-item">
                                                    <img className="productanimation" src="/images/product/611/tIMG_1260_1.jpg" width="600" height="399" alt="KC611" />
                                                </div>
                                                <div className="carousel-item">
                                                    <img className="productanimation" src="/images/product/611/tIMG_1261_1.jpg" width="600" height="399" alt="KC611" />
                                                </div>
                                                <div className="carousel-item">
                                                    <img className="productanimation" src="/images/product/611/tIMG_1265_1.jpg" width="600" height="399" alt="KC611" />
                                                </div>
                                                <div className="carousel-item">
                                                    <img className="productanimation" src="/images/product/611/tIMG_9896_1.jpg" width="600" height="399" alt="KC611" />
                                                </div>
                                            </div>
                                        </div>
                                    </figure>
                                </div>
                            </div>
                            <div className="col-md-4 col-sm-6 mix mb-0 group-3 fadeInUpLeft">
                                <div className="item-box">
                                    <figure>
                                        <span className="item-hover">
                                            <span className="overlay dark-5"></span>
                                            <span className="inner">
                                                <a className="ico-rounded lightbox" href="/images/product/612/IMG_1250_1.jpg" data-plugin-options='{"type":"image"}'>
                                                    <span className="fas fa-plus fs-20 rad-0"></span>
                                                </a>
                                                <a className="ico-rounded" href="/product/kc612">
                                                    <span className="fas fa-link fs-20 rad-0"></span>
                                                </a>
                                            </span>
                                        </span>
                                        <div className="item-box-overlay-title">
                                            <h3><Link to="/product/kc612" title="KC612">KC612</Link></h3>
                                            <ul className="list-inline categories m-0">
                                            <li><a href="javascript:void(0);" className="uppercase">{t("Product.Wired")}</a></li>
                                            </ul>
                                        </div>
                                        {/*<div className="owl-carousel buttons-autohide controlls-over m-0" data-plugin-options='{"singleItem": true, "autoPlay": 4000, "navigation": false, "pagination": true, "transitionStyle":"goDown"}'>*/}
                                        <div id="carouselIndicators-kc612" className="carousel" data-bs-ride="carousel">
                                            <div className="carousel-indicators member-4">
                                                <button type="button" data-bs-target="#carouselIndicators-kc612" data-bs-slide-to="0" className="active" aria-current="true" aria-label="Slide 1" style={{width:'20px', height:'6px'}}></button>
                                                <button type="button" data-bs-target="#carouselIndicators-kc612" data-bs-slide-to="1" aria-label="Slide 2" style={{width:'20px', height:'6px'}}></button>
                                                <button type="button" data-bs-target="#carouselIndicators-kc612" data-bs-slide-to="2" aria-label="Slide 3" style={{width:'20px', height:'6px'}}></button>
                                                <button type="button" data-bs-target="#carouselIndicators-kc612" data-bs-slide-to="3" aria-label="Slide 4" style={{width:'20px', height:'6px'}}></button>
                                            </div>

                                            <div className="carousel-inner">
                                                <div className="carousel-item active">
                                                    <img className="productanimation" src="/images/product/612/tIMG_1250_1.jpg" width="600" height="399" alt="KC612" />
                                                </div>
                                                <div className="carousel-item">
                                                    <img className="productanimation" src="/images/product/612/tIMG_1247_1.jpg" width="600" height="399" alt="KC612" />
                                                </div>
                                                <div className="carousel-item">
                                                    <img className="productanimation" src="/images/product/612/tIMG_1245_1.jpg" width="600" height="399" alt="KC612" />
                                                </div>
                                                <div className="carousel-item">
                                                    <img className="productanimation" src="/images/product/612/tIMG_1291_1.jpg" width="600" height="399" alt="KC612" />
                                                </div>
                                            </div>
                                        </div>  
                                    </figure>
                                </div>
                            </div>
                            <div className="col-md-4 col-sm-6 mix mb-0 group-3 fadeInRight">
                                <div className="item-box">
                                    <figure>
                                        <span className="item-hover">
                                            <span className="overlay dark-5"></span>
                                            <span className="inner">
                                                <a className="ico-rounded lightbox" href="/images/product/pic/EX200.png" data-plugin-options='{"type":"image"}'>
                                                    <span className="fas fa-plus fs-20 rad-0"></span>
                                                </a>
                                                <a className="ico-rounded" href="/product/ex200">
                                                    <span className="fas fa-link fs-20 rad-0"></span>
                                                </a>
                                            </span>
                                        </span>
                                        <div className="item-box-overlay-title">
                                            <h3><Link to="/product/ex200" title="EX200">EX200</Link></h3>
                                            <ul className="list-inline categories m-0">
                                                <li><a href="javascript:void(0);" className="uppercase">{t("Product.Wired")}</a></li>
                                            </ul>
                                        </div>
                                        {/*<div className="owl-carousel buttons-autohide controlls-over m-0" data-plugin-options='{"singleItem": true, "autoPlay": 4000, "navigation": false, "pagination": true, "transitionStyle":"goDown"}'>*/}
                                        <div className="productanimation">
                                            <div>
                                                <img className="img-fluid" src="/images/product/pic/tEX200.png" width="600" height="399" alt="EX200" />
                                            </div>
                                        </div>
                                    </figure>
                                </div>
                            </div>
                        </div>
                    </div>
                </form>
            </div>
        </section>
      </>
    )
  };
  
  export default Wired;