import { useTranslation } from 'react-i18next';
import { Link } from "react-router-dom";
import {Dropdown} from "react-bootstrap";

import Container from 'react-bootstrap/Container';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import NavDropdown from 'react-bootstrap/NavDropdown';

function Header() {
	const { t } = useTranslation();
    return (
		<div id="header" className="navbar-toggleable-md sticky clearfix bg-bluegreen border-bottom-0">
			<header id="topNav">
				<div className="container">
					{/*<button className="btn btn-mobile" data-toggle="collapse" data-target=".nav-main-collapse">
						<i className="fas fa-bars"></i>
					</button>*/}

	
					<button className="btn btn-mobile" >
					<Container fluid>
						<Dropdown>
							<Dropdown.Toggle  id="dropdown-basic">
								<i className="fas fa-bars"></i>								
							</Dropdown.Toggle>

							<Dropdown.Menu>							
								<Dropdown.Item href="/">{t("Header.Home")}</Dropdown.Item>			
								<Dropdown.Item href="/about">{t("Header.About KCodes")}</Dropdown.Item>		
								<Dropdown.Item href="/news">{t("Header.News")}</Dropdown.Item>			
									<Nav>
										
										<NavDropdown
										id="nav-dropdown-dark-example"
										title= {t("Header.Product")}
										menuVariant="dark"
										>
											<NavDropdown.Item href="/product/wired">{t("Header.Wired")}</NavDropdown.Item>
											<NavDropdown.Item href="/product/wireless">{t("Header.Wireless")}</NavDropdown.Item>	
											<NavDropdown.Item href="/product/softwaretechnology">{t("Header.Software Technology")}</NavDropdown.Item>
											<NavDropdown.Item href="/product/odmoem">{t("Header.ODM")}</NavDropdown.Item>								
										</NavDropdown>
									</Nav>
							
								<Dropdown.Item href="/contact">{t("Header.Contact Us")}</Dropdown.Item>
							</Dropdown.Menu>
						</Dropdown>
						</Container>
					</button>
			

{/*

						{/*<ul className="float-right nav nav-pills nav-second-main">
						<li className="search fullscreen">
							<a href="javascript:;">
								<i className="fas fa-search"></i>
							</a>
							<div className="search-box">
								<a id="closeSearch" href="#">X</a>
								<form action="https://www.kcodes.com/product/search" method="get">
									<div className="input-group">
										<input type="text" placeholder="Keyword" className="form-control" name="keyword"/>
										<span className="input-group-btn">
											<button className="btn btn-primary" type="submit"><i className="fas fa-search"></i></button>
										</span>
									</div>
								</form>
							</div>
						</li>
					</ul>*/}
		
				<Link className="logo float-left" to="/" title={t("Header.Home")}>
					<img src="/images/logo.svg" alt="" />
				</Link>

				

				<div className="navbar-collapse collapse float-right nav-main-collapse submenu-dark">
					<nav className="nav-main">
						<ul id="topMain" className="nav nav-pills nav-main">
							<li>
								{/* <a href="https://www.kcodes.com" title="Home">Home</a> */}
								<Link to="/" title={t("Header.Home")}>{t("Header.Home")}</Link>
							</li>
							<li className="dropdown">
							{/*	<a className="dropdown-toggle" href="#" onclick="javascript:if (document.body.clientWidth > 992) location.href='https://www.kcodes.com/about'" title="About KCodes">
									About KCodes	</a> */}
								<Link className="dropdown-toggle" to ="/about" onclick="javascript:if (document.body.clientWidth > 992) location.href='about'" title={t("Header.About KCodes")}>
								{t("Header.About KCodes")}&ensp;
								</Link>

						
							</li>
							<li className="dropdown">
								<Link className="dropdown-toggle" to="/news" onclick="javascript:if (document.body.clientWidth > 992) location.href='news'" title={t("Header.News")}>
								{t("Header.News")}&ensp;
								</Link>
						
							</li>
							<li className="dropdown ">
								<Link className="dropdown-toggle" to ="/product" onclick="javascript:if (document.body.clientWidth > 992) location.href='product'" title={t("Header.Product")}>
								{t("Header.Product")}&ensp;
								</Link>
								<ul className="dropdown-menu">
									<li><Link to="/product/wired" title={t("Header.Wired")}>{t("Header.Wired")}</Link></li>
									<li><Link to="/product/wireless" title={t("Header.Wireless")}>{t("Header.Wireless")}</Link></li>
									<li><Link to="/product/softwaretechnology" title={t("Header.Software Technology")}>{t("Header.Software Technology")}</Link></li>
									<li><Link to="/product/odmoem" title={t("Header.ODM")}>{t("Header.ODM")}</Link></li>
								</ul>
							</li>
							<li className="">
								<Link to="/contact" title={t("Header.Contact Us")}>{t("Header.Contact Us")}</Link>
							</li>
						</ul>
					</nav>
				</div>
				</div>
			</header>
		</div>
)
}
export default Header;


