import { useTranslation } from 'react-i18next';
import { Link } from "react-router-dom";
import React from 'react';

const Page3 = () => {
	const { t } = useTranslation();
    return(
    
      <> 		
	<section className="page-header page-header-xs">
	    <div className="container">
		    <h1 className="maven">{t("Page.Welcome to visit our booth at COMPUTEX TAIPEI 2019,  No.P0126, Nangang Hall 2, 1F")}</h1>
	       
	    </div>
    </section>

    <section>
	    <div className="container">
	    	<div className="row">
                <div className="col-lg-12">
                    <h2 className="h3 font-weight-bold blog-post-title">{t("Page.Welcome to visit our booth at COMPUTEX TAIPEI 2019,  No.P0126, Nangang Hall 2, 1F")}</h2>
                    <ul className="blog-post-info list-inline">
                        <li>
                            <i className="fas fa-clock-o"></i>
                            <span className="uppercase fs-12">Dec 20, 2018</span>
                        </li>
                        <li>
                            <i className="fas fa-tag"></i>
                            <span className="uppercase fs-12">{t("Page.Latest News")}</span>
                        </li>
                    </ul>
                </div>
		    </div>
            <div className="row">
                <div className="col-lg-12">
                    <p>{t("Page.We will exhibit 4 main product this year")}<br />{t("Page.1. USB Device Server")}<br />{t("Page.2. USB Extender")}<br />{t("Page.3. Wi-Fi Bridge")}<br />{t("Page.4. LINE/WeChat Print")}</p>
                    <p>{t("Page.Sincerely welcome you to stop by KCodes booth to see our new technology, at booth No. P0126, 1F, Nangang International exhibition center Hall 2")}</p>
                </div>
            </div>
            <div className="divider my-2"></div>
            <div className="news-in-foot">
                <div className="float-right">
                   <a href="javascript:history.back();" class="btn btn-sm btn-dark rad-0 btn-black uppercase" title={t("Page.Back")}><i class="fa fa-reply"></i>{t("Page.Back")}</a>
                </div>
            </div>
	    </div>
    </section>

      </>
    )
  };


  export default Page3;