import { useTranslation } from 'react-i18next';
import { Link } from "react-router-dom";
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { Carousel } from 'react-responsive-carousel';
const EPAPER = () => {
    const { t } = useTranslation();
    return (
        <>
        <section className="page-header page-header-xs">
            <div className="container">
                <h1 className="maven">KCodes&nbsp;ePaper</h1>
                <ol className="breadcrumb">
                    <li><Link to="/" title={t("Product.Home")}>{t("Product.Home")}</Link></li>
                    <li><Link to="/product" title={t("Product.Product")}>{t("Product.Product")}</Link></li>
                    <li><Link to="/product/odmoem" title={t("Product.ODM & OEM")}>{t("Product.ODM & OEM")}</Link></li>
                    <li><Link to="/product/epaper" title="ePaper">ePaper</Link></li>
                </ol>
            </div>
        </section>

        <section className="product-in-box border-bottom-0">
            <div className="container">
                <div className="row">
                    <div className="col-lg-7">
                        <div className="thumbnail mb-8 radius-0 mb-0 border-0 p-0">
                            <figure id="zoom-primary" className="zoom" data-mode="mouseover">
                                <Carousel width={'100%'} thumbWidth={'40%'} showStatus={false} showIndicators={false} centerMode={false} showArrows={false}>
                                    <div>
                                        <a className="search-product" href="/images/product/pic/ePaper.jpg" title="ePaper" data-plugin-options='{"type":"image"}'>
                                            <i className="fas fa-search-plus pt-2 m-6" aria-hidden="true"></i>
                                        </a>
                                        <img className="img-product" src="/images/product/pic/ePaper.jpg" height="100" alt="ePaper" />             
                                    </div>
                                </Carousel>
                            </figure>
                        </div>
                    </div>
                   
                    <div className="col-lg-5">
                        <div className="product-name mb-20">
                            <h2 className="capitalize mb-0 pb-10">KCodes&nbsp;<span style={{'text-transform':'lowercase', color:'#505067'}}>e</span>Paper</h2>
                        </div>
                        <div className="mb-30">
                            <h5 className="uppercase">
                                <i className="fas fa-bookmark pr-5"></i> {t("Product.ODM & OEM")}
                            </h5>
                        </div>
                        <p>{t("Product.ePaper Solutions")}<br />
                        ◎ {t("Product.H/W Development")}<br />
                        ◎ {t("Product.ePaper Application")}<br />
                        ◎ {t("Product.System Integration")}<br />
                        ◎ {t("Product.Software Customization")}</p>
                            
                        <div className="row">
                            <div className="float-lg-right">
                            </div>
                            <div className="col-lg-6 mb-10">
                            <a href="javascript:history.back();" className="btn btn-gotop-shadow btn-info rad-0" style={{background:'#000000'}} title={t("Product.Back")}>
                                    <span className="capitalize" style={{color:'#ffff'}}><i className="fa fa-undo-alt pr-10"></i> {t("Product.Back")} </span>
                                </a>
                            </div>

                            <div className="col-lg-6 mb-10">
                                <Link to="/contact" className="btn btn-gotop-shadow btn-info rad-0" style={{background:'#0190a0'}} title={t("Product.Contact Us")}>
                                    <span className="capitalize" style={{color:'#ffff'}}> {t("Product.Contact Us")} <i className="fas fa-envelope-o pl-10"></i></span>
                                </Link>
                            </div>
                        </div>
                        <hr />
                    </div>
                </div>

                <div className="row mt-40">
                    <div className="col-md-12">
                        <nva>                       
                            <div className="nav nav-tabs nav-button-tabs" id="nav-tab" role="tablist">
                            <li className="nav-item"><a className="nav-link active" id="nav-content-tab" data-bs-toggle="tab" data-bs-target="#nav-content" type="button" role="tab" aria-controls="nav-content" aria-selected="true">{t("Product.Overview")}</a></li>
                            </div>
                        </nva>
                        
                        <div className="tab-content" id="nav-tabContent">
                            <div className="tab-pane fade show active" id="nav-content" role="tabpanel" aria-labelledby="nav-content-tab">
                                <table width="100%">
                                <tbody>
                                        <tr>
                                            <td width="566">
                                                <p><strong><span style={{fontSize: '14pt'}}>{t("Product.ePaper Solutions")}</span></strong></p>
                                                <p><span style={{fontSize: '12pt'}}>◎ {t("Product.H/W Development")}</span>
                                                <br /><span style={{fontSize: '12pt'}}>◎ {t("Product.ePaper Application")}</span>
                                                <br /><span style={{fontSize: '12pt'}}>◎ {t("Product.System Integration")}</span>
                                                <br /><span style={{fontSize: '12pt'}}>◎ {t("Product.Software Customization")}&nbsp;</span></p>
                                            </td>
                                        </tr>
                                    </tbody>

                                </table>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
      </>
    )
  };
  
  export default EPAPER;