import {Outlet} from "react-router-dom";

const Contact = () => {
    return (
      <>
        <Outlet />
      </>
    )
  };
  
  export default Contact;