import { useTranslation } from 'react-i18next';
import { Link } from "react-router-dom";
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { Carousel } from 'react-responsive-carousel';
const KC601n = () => {
    const { t } = useTranslation();
    return (
      <>
        <section className="page-header page-header-xs">
            <div className="container">
                <h1 className="maven">KC601n</h1>
                <ol className="breadcrumb">
                    <li><Link to="/" title={t("Product.Home")}>{t("Product.Home")}</Link></li>
                    <li><Link to="/product" title={t("Product.Product")}>{t("Product.Product")}</Link></li>
                    <li><Link to="/product/wireless" title={t("Product.Wireless")}>{t("Product.Wireless")}</Link></li>
                    <li><Link to="/product/kc601n" title="KC601n">KC601n</Link></li>
                </ol>
            </div>
        </section>

        <section className="product-in-box border-bottom-0">
            <div className="container">
                <div className="row">
                    <div className="col-lg-7">
                        <div className="thumbnail mb-8 radius-0 mb-0 border-0 p-0">
                            <figure id="zoom-primary" className="zoom" data-mode="mouseover">
                                {/*<a className="lightbox bottom-right rad-0 d-none d-sm-block" href="/images/product/601n/IMG_1231_1.jpg" title="KC601n" data-plugin-options='{"type":"image"}'>
                                    <i className="fas fa-search-plus" aria-hidden="true"></i>
                                </a>
                              <img className="img-fluid" src="/images/product/601n/IMG_1231_1.jpg" width="1200" height="1500" alt="KC601n" />*/}
                                <Carousel width={'100%'} thumbWidth={'23%'} showStatus={false} showIndicators={false} centerMode={false} infiniteLoop useKeyboardArrows autoPlay showArrows={false} >
                                    <div>
                                        <a className="search-product" href="/images/product/601n/IMG_1231_1.jpg" title="KC601n" data-plugin-options='{"type":"image"}'>
                                            <i className="fas fa-search-plus pt-2 m-6" aria-hidden="true"></i>
                                        </a>
                                        <img className="img-product" src="/images/product/601n/tIMG_1231_1.jpg" height="100" alt="KC601n" />
                                    </div>
                                    <div>
                                        <a className="search-product" href="/images/product/601n/IMG_1227_1.jpg" title="KC601n" data-plugin-options='{"type":"image"}'>
                                            <i className="fas fa-search-plus pt-2 m-6" aria-hidden="true"></i>
                                        </a>
                                        <img className="img-product" src="/images/product/601n/tIMG_1227_1.jpg" height="100" alt="KC601n" />
                                    </div>
                                        <div>
                                        <a className="search-product" href="/images/product/601n/IMG_1235_1.jpg" title="KC601n" data-plugin-options='{"type":"image"}'>
                                            <i className="fas fa-search-plus pt-2 m-6" aria-hidden="true"></i>
                                        </a>
                                        <img className="img-product" src="/images/product/601n/tIMG_1235_1.jpg" height="100" alt="KC601n" />
                                    </div>
                                </Carousel>

                            </figure>
                        </div>
                        {/*<div data-for="zoom-primary" className="owl-carousel-2 zoom-more featured controlls-over"
                            data-plugin-options='{
                            "responsiveBaseElement":"#wrapper",
                            "loop":false,
                            "margin":0,
                            "nav":true,
                            "dots":false,
                            "center":false,
                            "slideBy":"1",
                            "autoplay":false,
                            "autoplayTimeout":4500,
                            "autoWidth":false,
                            "merge":true,
                            "rtl":false,
                            "animateIn":"",
                            "animateOut":"",
                            "responsive": {
                            "0":	{"items":3},
                            "960":	{"items":5}
                            }
                            }'>
                            <Link className="thumbnail rad-0 active" to="/images/product/601n/IMG_1231_1.jpg">
                                <img src="/images/product/601n/tIMG_1231_1.jpg" height="100" alt="KC601n" />
                            </Link>
                            <Link className="thumbnail rad-0 active" to="/images/product/601n/IMG_1227_1.jpg">
                                <img src="/images/product/601n/tIMG_1227_1.jpg" height="100" alt="KC601n" />
                            </Link>
                            <Link className="thumbnail rad-0 active" to="/images/product/601n/IMG_1235_1.jpg">
                                <img src="/images/product/601n/tIMG_1235_1.jpg" height="100" alt="KC601n" />
                            </Link>
                        </div>*/}
                    </div>
                    <div className="col-lg-5">
                        <div className="product-name mb-20">
                            <h2 className="capitalize mb-0 pb-10">KC601n</h2>
                        </div>
                        <div className="mb-30">
                            <h5 className="uppercase">
                                <i className="fas fa-bookmark pr-5"></i> {t("Product.Wireless")}
                            </h5>
                        </div>
                        <p>【USB Device Server】<br />
                        ◎ {t("Product.Global patented technology - USB Over IP")}<br />
                        ◎ {t("Product.Customized control center supporting multiple languages")}<br />
                        ◎ {t("Product.Highly compatibe with printers in the market")}<br />
                        ◎ {t("Product.On-demand after service and technical support")}<br />
                        ◎ {t("Product.Support wireless connectivity 802.11n")}<br />
                        ◎ {t("Product.CE/FCC completed")}</p>
                        <div className="row">
                            <div className="float-lg-right">
                            </div>
                            <div className="col-lg-6 mb-10">
                                <a href="javascript:history.back();" className="btn btn-gotop-shadow btn-info rad-0" style={{background:'#000000'}} title={t("Product.Back")}>
                                    <span className="capitalize" style={{color:'#ffff'}}><i className="fa fa-undo-alt pr-10"></i> {t("Product.Back")} </span>
                                </a>
                            </div>

                            <div className="col-lg-6 mb-10">
                                <Link to="/contact" className="btn btn-gotop-shadow btn-info rad-0" style={{background:'#0190a0'}} title={t("Product.Contact Us")}>
                                    <span className="capitalize" style={{color:'#ffff'}}> {t("Product.Contact Us")} <i className="fas fa-envelope-o pl-10"></i></span>
                                </Link>
                            </div>
                        </div>
                      <hr />
                    </div>
                </div>
                <div className="row mt-40">
                    <div className="col-md-12">
                        {/*<ul className="nav nav-tabs nav-button-tabs">
                            <li className="nav-item"><a className="nav-link active" href="#content" data-toggle="tab">Overview</a></li>
                            <li className="nav-item"><a className="nav-link" href="#content1" data-toggle="tab">Specification</a></li>
                        </ul>
                        <div className="tab-content">
                            <div className="tab-pane active" id="content">
                                <table width="100%">
                                <tbody>
                                <tr>
                                <td width="566">
                                <p><strong><span style={{fontSize: '14pt'}}>【USB Device Server】</span></strong></p>
                                <p><span style={{fontSize: '12pt'}}> KC601n is a sinlge port wireless USB device server. </span>
                                <br /><span style={{fontSize: '12pt'}}> The KC601n Wireless USB Device Server replaces the LAN connection.</span>
                                <br /><span style={{fontSize: '12pt'}}> Users can easily connect and share USB devices over the network.</span>
                                <br /><span style={{fontSize: '12pt'}}> Installation of KCodes utility is required.&nbsp;</span></p>
                                </td>
                                </tr>
                                </tbody>
                                </table>
                                <p><img src="/images/product/pic/os.jpg" alt="" width="450" height="272" /><img src="/images/product/pic/USB-server-2.0.jpg" alt="" width="1000" height="647" /></p>
                            </div>
                            <div className="tab-pane fade" id="content1">
                                <p><img src="/images/product/SPEC/601n.jpg" alt="" width="457" height="512" /></p>
                            </div>
                        </div>*/}
                        <nva>                       
                            <div className="nav nav-tabs nav-button-tabs" id="nav-tab" role="tablist">
                            <li className="nav-item"><a className="nav-link active" id="nav-content-tab" data-bs-toggle="tab" data-bs-target="#nav-content" type="button" role="tab" aria-controls="nav-content" aria-selected="true">{t("Product.Overview")}</a></li>
                            <li className="nav-item"><a className="nav-link" id="nav-content1-tab" data-bs-toggle="tab" data-bs-target="#nav-content1" type="button" role="tab" aria-controls="nav-content1" aria-selected="false">{t("Product.Specification")}</a></li>
                            {/*<li className="nav-item"><a className="nav-link" id="nav-download-tab" data-bs-toggle="tab" data-bs-target="#nav-download" type="button" role="tab" aria-controls="nav-download" aria-selected="false">Download</a></li>*/}
                            </div>
                        </nva>
                        
                        <div className="tab-content" id="nav-tabContent">
                            <div className="tab-pane fade show active" id="nav-content" role="tabpanel" aria-labelledby="nav-content-tab">
                                <table width="100%">
                                    <tbody>
                                        <tr>
                                            <td width="566">
                                                <p><strong><span style={{fontSize: '14pt'}}>【USB Device Server】</span></strong></p>
                                                <p><span style={{fontSize: '12pt'}}> {t("Product.KC601n is a sinlge port wireless USB device server.")} </span>
                                                <br /><span style={{fontSize: '12pt'}}> {t("Product.The KC601n Wireless USB Device Server replaces the LAN connection.")}</span>
                                                <br /><span style={{fontSize: '12pt'}}> {t("Product.Users can easily connect and share USB devices over the network.")}</span>
                                                <br /><span style={{fontSize: '12pt'}}> {t("Product.Installation of KCodes utility is required.")}&nbsp;</span></p>
                                            </td>
                                        </tr>
                                    </tbody>
                                    </table>
                                <p><img src="/images/product/pic/os.jpg" alt="" width="450" height="272" /><img src="/images/product/pic/USB-server-2.0.jpg" alt="" width="1000" height="647" /></p>
                            </div>
                            <div className="tab-pane fade" id="nav-content1" role="tabpanel" aria-labelledby="nav-content1-tab">
                                <p><img src="/images/product/SPEC/601n.jpg" alt="" width="457" height="512" /></p>
                            </div>
                            {/*<div className="tab-pane fade" id="nav-download" role="tabpanel" aria-labelledby="nav-download-tab">
                                <table className="table download-table">
                                    <thead>
                                        <tr>
                                            <th scope="col">Description</th>
                                            <th scope="col">Download</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr>
                                            <td>User Manual</td>
                                            <td><Link to="/images/product/pic/DMS.jpg" title="DMS" target="_blank"><img src="/images/download-img.png" alt="Download" style={{width: '50px'}} /></Link></td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>*/}
                        </div>
                    </div>
                </div>
            </div>
        </section>
      </>
    )
  };
  
  export default KC601n;