import { useTranslation } from 'react-i18next';
import { Link } from "react-router-dom";
import React from 'react';

const Page4 = () => {
	const { t } = useTranslation();
    return(
    
      <> 		
	<section className="page-header page-header-xs">
		<div className="container">
			<h1 className="maven">{t("Page.KCodes launched USB3.0 Device Server solutions")}</h1>

		</div>
	</section>

	<section>
		<div className="container">
			<div className="row">
				<div className="col-lg-12">
					<h2 className="h3 font-weight-bold blog-post-title">{t("Page.KCodes launched USB3.0 Device Server solutions")}</h2>
					<ul className="blog-post-info list-inline">
						<li>
							<i className="fas fa-clock-o"></i>
							<span className="uppercase fs-12">Jan 15, 2018</span>
						</li>
						<li>
							<i className="fas fa-tag"></i>
							<span className="uppercase fs-12">{t("Page.Latest News")}</span>
						</li>
					</ul>
				</div>
			</div>
			<div className="row">
				<div className="col-lg-12">
					<p>{t("Page.KCodes new product - USB Device Server, featured with USB 3.0 super speed and Giga LAN transmission.")} <br />
					{t("Page.Bundled with KCodes NetUSB software technology, can reach higher speed and support more USB device.")}<br />
					{t("Page.You are welcom to contact us to have technical discussion and future cooperation.")}</p>
				</div>
			</div>
			<div className="divider my-2"></div>
			<div className="news-in-foot">
				<div className="float-right">
					<a href="javascript:history.back();" class="btn btn-sm btn-dark rad-0 btn-black uppercase" title={t("Page.Back")}><i class="fa fa-reply"></i>{t("Page.Back")}</a>
				</div>
			</div>
		</div>
	</section>

      </>
    )
  };


  export default Page4;