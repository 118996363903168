import { useTranslation } from 'react-i18next';
import { Link } from "react-router-dom";
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { Carousel } from 'react-responsive-carousel';
const EX200 = () => {
    const { t } = useTranslation();
    return (
      <>
		<section className="page-header page-header-xs">
            <div className="container">
                <h1 className="maven">EX200</h1>
                <ol className="breadcrumb">
                    <li><Link to="/" title={t("Product.Home")}>{t("Product.Home")}</Link></li>
                    <li><Link to="/product" title={t("Product.Product")}>{t("Product.Product")}</Link></li>
                    <li><Link to="/product/wired" title={t("Product.Wired")}>{t("Product.Wired")}</Link></li>
                    <li><Link to="/product/ex200" title="EX200">EX200</Link></li>
                </ol>
            </div>
        </section>

        <section className="product-in-box border-bottom-0">
            <div className="container">
                <div className="row">
                    <div className="col-lg-7">
                        <div className="thumbnail mb-8 radius-0 mb-0 border-0 p-0">
                            <figure id="zoom-primary" className="zoom" data-mode="mouseover">
                                {/*<a className="lightbox bottom-right rad-0 d-none d-sm-block" href="/images/product/pic/EX200.png" title="EX200" data-plugin-options='{"type":"image"}'>
                                    <i className="fas fa-search-plus" aria-hidden="true"></i>
                                </a>
                                <img className="img-fluid" src="/images/product/pic/EX200.png" width="1200" height="1500" alt="EX200" />*/}
                                <Carousel width={'100%'} thumbWidth={'40%'} showStatus={false} showIndicators={false} centerMode={false} showArrows={false}>
                                    <div>
                                        <a className="search-product" href="/images/product/pic/EX200.png" title="EX200" data-plugin-options='{"type":"image"}'>
                                            <i className="fas fa-search-plus pt-2 m-6" aria-hidden="true"></i>
                                        </a>
                                        <img className="img-product" src="/images/product/pic/tEX200.png" height="100" alt="EX200" />             
                                    </div>
                                </Carousel>

                            </figure>
                        </div>
                        {/*<div data-for="zoom-primary" className="owl-carousel-2 zoom-more featured controlls-over"
                            data-plugin-options='{
                                "responsiveBaseElement":"#wrapper",
                                "loop":false,
                                "margin":0,
                                "nav":true,
                                "dots":false,
                                "center":false,
                                "slideBy":"1",
                                "autoplay":false,
                                "autoplayTimeout":4500,
                                "autoWidth":false,
                                "merge":true,
                                "rtl":false,
                                "animateIn":"",
                                "animateOut":"",
                                "responsive": {
                                    "0":	{"items":3},
                                    "960":	{"items":5}
                                }
                                }'>
                            <Link className="thumbnail rad-0 active" to="/images/product/pic/EX200.png">
                                <img src="/images/product/pic/tEX200.png" height="100" alt="EX200" />
                            </Link>
                        </div>*/}
                    </div>
                    <div className="col-lg-5">
                        <div className="product-name mb-20">
                            <h2 className="capitalize mb-0 pb-10">EX200</h2>
                        </div>
                        <div className="mb-30">
                            <h5 className="uppercase">
                                <i className="fas fa-bookmark pr-5"></i> {t("Product.Wired")}
                            </h5>
                        </div>
                        <p>【USB 2.0 Extender】<br />
                            ◎ {t("Product.Giga LAN(10/100/1000)")}<br />
                            ◎ {t("Product.Global patented technology - USB Over IP")}<br />
                            ◎ {t("Product.Supports 1080P video smooth and non-interruption")}<br />
                            ◎ {t("Product.Customized software service")}<br />
                            ◎ {t("Product.On-demand after service and technical support")}</p>
                        <div className="row">
                            <div className="float-lg-right">
                            </div>
                            <div className="col-lg-6 mb-10">
                                <a href="javascript:history.back();" className="btn btn-gotop-shadow btn-info rad-0" style={{background:'#000000'}} title={t("Product.Back")}>
                                    <span className="capitalize" style={{color:'#ffff'}}><i className="fa fa-undo-alt pr-10"></i> {t("Product.Back")} </span>
                                </a>
                            </div>

                            <div className="col-lg-6 mb-10">
                                <Link to="/contact" className="btn btn-gotop-shadow btn-info rad-0" style={{background:'#0190a0'}} title={t("Product.Contact Us")} >
                                    <span className="capitalize" style={{color:'#ffff'}}> {t("Product.Contact Us")}  <i className="fas fa-envelope-o pl-10"></i></span>
                                </Link>
                            </div>
                        </div>
                        <hr />
                    </div>
                </div>

                <div className="row mt-40">
                    <div className="col-md-12">
                        {/*<ul className="nav nav-tabs nav-button-tabs">
                            <li className="nav-item"><a className="nav-link active" href="#content" data-toggle="tab">Overview</a></li>
                            <li className="nav-item"><a className="nav-link" href="#content1" data-toggle="tab">Specification</a></li>
                        </ul>
                        <div className="tab-content">
                            <div className="tab-pane active" id="content">
                                <p><span style={{fontSize: '14pt'}}><strong>【USB 2.0 Extender】</strong></span></p>
                                <p><span style={{fontSize: '12pt'}}> Plug and play, spec with high performance CPU and Giga LAN</span>
                                <br /><span style={{fontSize: '12t'}}> Execute 1080P video conference call without interruption.</span>
                                <br /><span style={{fontSize: '12t'}}> Get rid of other extender in market, EX200 use KCodes patented software technology, can extend signal stably. </span>
                                <br /><span style={{fontSize: '12t'}}> With LAN cable length up to 100 meters.</span><br />
                                <span style={{fontSize: '12t'}}> To meet customer requirement, KCodes provide software customization service, recommend use with surveillance or video conference system. </span></p>
                            </div>
                            <div className="tab-pane fade" id="content1">
                                <p><img src="/images/product/SPEC/EX200-T.jpg" alt="" width="519" height="259" /></p>
                                <p><img src="/images/product/SPEC/EX200-R.jpg" alt="" width="517" height="256" /></p>
                            </div>
                        </div>*/}

<nva>                       
                            <div className="nav nav-tabs nav-button-tabs" id="nav-tab" role="tablist">
                            <li className="nav-item"><a className="nav-link active" id="nav-content-tab" data-bs-toggle="tab" data-bs-target="#nav-content" type="button" role="tab" aria-controls="nav-content" aria-selected="true">{t("Product.Overview")}</a></li>
                            <li className="nav-item"><a className="nav-link" id="nav-content1-tab" data-bs-toggle="tab" data-bs-target="#nav-content1" type="button" role="tab" aria-controls="nav-content1" aria-selected="false">{t("Product.Specification")}</a></li>
                            {/*<li className="nav-item"><a className="nav-link" id="nav-download-tab" data-bs-toggle="tab" data-bs-target="#nav-download" type="button" role="tab" aria-controls="nav-download" aria-selected="false">Download</a></li>*/}
                            </div>
                        </nva>
                        
                        <div className="tab-content" id="nav-tabContent">
                            <div className="tab-pane fade show active" id="nav-content" role="tabpanel" aria-labelledby="nav-content-tab">
                                <p><span style={{fontSize: '14pt'}}><strong>【USB 2.0 Extender】</strong></span></p>
                                <p><span style={{fontSize: '12pt'}}> {t("Product.Plug and play, spec with high performance CPU and Giga LAN.")}</span>
                                <br /><span style={{fontSize: '12t'}}> {t("Product.Execute 1080P video conference call without interruption.")}</span>
                                <br /><span style={{fontSize: '12t'}}> {t("Product.Get rid of other extender in market, EX200 use KCodes patented software technology, can extend signal stably.")} </span>
                                <br /><span style={{fontSize: '12t'}}> {t("Product.With LAN cable length up to 100 meters.")}</span><br />
                                <span style={{fontSize: '12t'}}> {t("Product.To meet customer requirement, KCodes provide software customization service, recommend use with surveillance or video conference system.")} </span></p>
                            </div>
                            <div className="tab-pane fade" id="nav-content1" role="tabpanel" aria-labelledby="nav-content1-tab">
                                <p><img src="/images/product/SPEC/EX200-T.jpg" alt="" width="519" height="259" /></p>
                                <p><img src="/images/product/SPEC/EX200-R.jpg" alt="" width="517" height="256" /></p>
                            </div>
                        </div>

                        {/*<div className="tab-pane fade" id="nav-download" role="tabpanel" aria-labelledby="nav-download-tab">
                            <table className="table download-table">
                                <thead>
                                    <tr>
                                        <th scope="col">Description</th>
                                        <th scope="col">Download</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr>
                                        <td>User Manual</td>
                                        <td><Link to="/images/product/UserManual/KCodes-601-v2-USB-Device-Server-v2.0.pdf" title="KC601 User Manual" target="_blank"><img src="/images/download-img.png" alt="Download" style={{width: '50px'}} /></Link></td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>*/}
                    </div>
                </div>
            </div>
        </section>
      </>
    )
  };
  
  export default EX200;