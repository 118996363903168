import { useTranslation } from 'react-i18next';
import { Link } from "react-router-dom";
import React from 'react';

const Page1 = () => {
  const { t } = useTranslation();
    return(   
      <> 		
		<section className="page-header page-header-xs">
      <div className="container">
        <h1 className="maven">{t("Page.KCodes launched Wi-Fi Bridge solution")}</h1>
      
      </div>
    </section>

    <section>
      <div className="container">
        <div className="row">
          <div className="col-lg-12">
            <h2 className="h3 font-weight-bold blog-post-title">{t("Page.KCodes launched Wi-Fi Bridge solution")}</h2>
            <ul className="blog-post-info list-inline">
                <li>
                    <i className="fas fa-clock-o"></i>
                    <span className="uppercase fs-12">Jan 10, 2019</span>
                </li>
                <li>
                    <i className="fas fa-tag"></i>
                    <span className="uppercase fs-12">{t("Page.Latest News")}</span>
                </li>
            </ul>
          </div>
          
          <div className="col-lg-12">
              <p>{t("Page.Our Wi-Fi Bridge can support 2.4GHz&5GHz.")}<br />
              {t("Page.Without complex setting, easy to switch your LAN connection to Wireless.")}<br />
              {t("Page.Please contact us to have further technical discussion and cooperation.")}
              </p>
          </div>
      
          <div className="divider my-2"></div>
          <div className="news-in-foot">
            <div className="float-right">
            <a href="javascript:history.back();" class="btn btn-sm btn-dark rad-0 btn-black uppercase" title={t("Page.Back")}><i class="fa fa-reply"></i>{t("Page.Back")}</a>
            </div>
          </div>
        </div>
      </div>
      
    </section>
      </>
    )
  };


  export default Page1;