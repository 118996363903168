import { useTranslation } from 'react-i18next';
import { Link } from "react-router-dom";
import React from 'react';

const Page5 = () => {
	const { t } = useTranslation();
    return(
    
      <> 		
	<section className="page-header page-header-xs">
	    <div className="container">
            <h1 className="maven">{t("Page.Welcome to our booth at COMPUTEX TAIPEI 2018,  No. M1003, Nangang Hall 1, 4F")}</h1>
 
        </div>
    </section>

    <section>
        <div className="container">
            <div className="row">
                <div className="col-lg-12">
                    <h2 className="h3 font-weight-bold blog-post-title">{t("Page.Welcome to our booth at COMPUTEX TAIPEI 2018,  No. M1003, Nangang Hall 1, 4F")}</h2>
                    <ul className="blog-post-info list-inline">
                        <li>
                            <i className="fas fa-clock-o"></i>
                            <span className="uppercase fs-12">Nov 30, 2017</span>
                        </li>
                        <li>
                            <i className="fas fa-tag"></i>
                            <span className="uppercase fs-12">{t("Page.Latest News")}</span>
                        </li>
                    </ul>
                </div>
            </div>
            <div className="row">
                <div className="col-lg-12">
                    <p>{t("Page.This year, KCodes proudly presents you our latest USB Device Server.")} <br />
                    {t("Page.Our new KC611/612ac support USB3.0 and Giga LAN transmission.With KC611 and KC612ac, you can instantly share your USB devices with multiple users at any place.")}</p>
                    <p>{t("Page.Moreover, we have innovative Smart Home solution demonstration at our booth.This new feature enables users to access USB camera by instant messaging.")}</p>
                    <p>{t("Page.You are very welcome to visit us at booth No. M1003, 4F, Nangang Hall 1.")}</p>
			    </div>
		    </div>
            <div className="divider my-2"></div>
            <div className="news-in-foot">
                <div className="float-right">
                <a href="javascript:history.back();" class="btn btn-sm btn-dark rad-0 btn-black uppercase" title={t("Page.Back")}><i class="fa fa-reply"></i>{t("Page.Back")}</a>
                </div>
            </div>
        </div>
</section>

      </>
    )
  };


  export default Page5;