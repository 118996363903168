import { useTranslation } from 'react-i18next';
import { Link } from "react-router-dom";
const  NewsMain1= () => {
    const { t } = useTranslation();
    return (
      <>
        <section className="page-header page-header-xs">
            <div className="container">
                <h1 className="maven">{t("NewsMain.News")}</h1>
                
            </div>
        </section>

        <section>
            <div className="container">
                <form name="searchform" id="searchform" method="get">
                    <div id="portfolio" className="portfolio-gutter portfolio-title-over ls-overflow-visible">
                        <div className="row mix-grid11">


                        <div className="blog-post-item col-md-12 mb-20 border-bottom-0 pb-0 mix group-1">
                                <div className="bg-white p-20 box-shadow1 btm-linebg">
                                    <h4 className="mb-10"><Link to="/Page8" title={t("NewsMain.KCodes launched Wireless IoT solutions for Tuner Module")} className="color-primary color-hover-dark">{t("NewsMain.KCodes launched Wireless IoT solutions for Tuner Module")}</Link></h4>
                                    <ul className="blog-post-info list-inline mb-10 pb-0">
                                        <li>
                                            <i className="fas fa-clock-o"></i>
                                            <span className="uppercase fs-12">Apr 01, 2016</span>
                                        </li>
                                        <li>
                                            <i className="fas fa-tag"></i>
                                            <span className="uppercase fs-12">{t("NewsMain.Latest News")}</span>
                                        </li>
                                    </ul>
                                    <p className="mb-20">...</p>
                                    <Link to="/Page8" title={t("NewsMain.Read More")} className="btn btn-primary btn-sm rad-0">{t("NewsMain.Read More")}<i className="fas fa-angle-right pl-10"></i></Link>
                                </div>
                            </div>
                            <div className="blog-post-item col-md-12 mb-20 border-bottom-0 pb-0 mix group-1">
                                <div className="bg-white p-20 box-shadow1 btm-linebg">
                                    <h4 className="mb-10"><Link to="/Page9" title={t("NewsMain.KCodes launched Wi-Fi Docking SW solutions")} className="color-primary color-hover-dark">{t("NewsMain.KCodes launched Wi-Fi Docking SW solutions")}</Link></h4>
                                    <ul className="blog-post-info list-inline mb-10 pb-0">
                                        <li>
                                            <i className="fas fa-clock-o"></i>
                                            <span className="uppercase fs-12">Oct 26, 2015</span>
                                        </li>
                                        <li>
                                            <i className="fas fa-tag"></i>
                                            <span className="uppercase fs-12">{t("NewsMain.Latest News")}</span>
                                        </li>
                                    </ul>
                                    <p className="mb-20">...</p>
                                    <Link to="/Page9" title={t("NewsMain.Read More")} className="btn btn-primary btn-sm rad-0">{t("NewsMain.Read More")}<i className="fas fa-angle-right pl-10"></i></Link>
                                </div>
                            </div>
                            <div className="blog-post-item col-md-12 mb-20 border-bottom-0 pb-0 mix group-1">
                                <div className="bg-white p-20 box-shadow1 btm-linebg">
                                    <h4 className="mb-10"><Link to="/Page10" title={t("NewsMain.KCodes acquire the patent of accelerated signal transmission system and method")} className="color-primary color-hover-dark">{t("NewsMain.KCodes acquire the patent of accelerated signal transmission system and method")}</Link></h4>
                                    <ul className="blog-post-info list-inline mb-10 pb-0">
                                        <li>
                                            <i className="fas fa-clock-o"></i>
                                            <span className="uppercase fs-12">Oct 21, 2014</span>
                                        </li>
                                        <li>
                                            <i className="fas fa-tag"></i>
                                            <span className="uppercase fs-12">{t("NewsMain.Latest News")}</span>
                                        </li>
                                    </ul>
                                    <p className="mb-20">...</p>
                                    <Link to="/Page10" title={t("NewsMain.Read More")} className="btn btn-primary btn-sm rad-0">{t("NewsMain.Read More")}<i className="fas fa-angle-right pl-10"></i></Link>
                                </div>
                            </div>
                            <div className="blog-post-item col-md-12 mb-20 border-bottom-0 pb-0 mix group-1">
                                <div className="bg-white p-20 box-shadow1 btm-linebg">
                                    <h4 className="mb-10"><Link to="/Page11" title={t("NewsMain.KCodes obtain patent for method of automatic connection with remote USB devices")} className="color-primary color-hover-dark">{t("NewsMain.KCodes obtain patent for method of automatic connection with remote USB devices")}</Link></h4>
                                    <ul className="blog-post-info list-inline mb-10 pb-0">
                                        <li>
                                            <i className="fas fa-clock-o"></i>
                                            <span className="uppercase fs-12">Jul 17, 2013</span>
                                        </li>
                                        <li>
                                            <i className="fas fa-tag"></i>
                                            <span className="uppercase fs-12">{t("NewsMain.Latest News")}</span>
                                        </li>
                                    </ul>
                                    <p className="mb-20">...</p>
                                    <Link to="/Page11" title={t("NewsMain.Read More")} className="btn btn-primary btn-sm rad-0">{t("NewsMain.Read More")}<i className="fas fa-angle-right pl-10"></i></Link>
                                </div>
                            </div>
                            <div className="blog-post-item col-md-12 mb-20 border-bottom-0 pb-0 mix group-1">
                                <div className="bg-white p-20 box-shadow1 btm-linebg">
                                    <h4 className="mb-10"><Link to="/Page12" title={t("NewsMain.KCodes launched Video streaming")} className="color-primary color-hover-dark">{t("NewsMain.KCodes launched Video streaming")}</Link></h4>
                                    <ul className="blog-post-info list-inline mb-10 pb-0">
                                        <li>
                                            <i className="fas fa-clock-o"></i>
                                            <span className="uppercase fs-12">Jul 01, 2013</span>
                                        </li>
                                        <li>
                                            <i className="fas fa-tag"></i>
                                            <span className="uppercase fs-12">{t("NewsMain.Latest News")}</span>
                                        </li>
                                    </ul>
                                    <p className="mb-20">...</p>
                                    <Link to="/Page12" title={t("NewsMain.Read More")} className="btn btn-primary btn-sm rad-0">{t("NewsMain.Read More")}<i className="fas fa-angle-right pl-10"></i></Link>
                                </div>
                            </div>
                            
                            
                            <div className="blog-post-item col-md-12 mb-20 border-bottom-0 pb-0 mix group-1">
                                <div className="bg-white p-20 box-shadow1 btm-linebg">
                                    <h4 className="mb-10"><Link to="/Page13" title={t("NewsMain.KCodes launched audio streaming solution and WiFi scanning solutions")} className="color-primary color-hover-dark">{t("NewsMain.KCodes launched audio streaming solution and WiFi scanning solutions")}</Link></h4>
                                    <ul className="blog-post-info list-inline mb-10 pb-0">
                                        <li>
                                            <i className="fas fa-clock-o"></i>
                                            <span className="uppercase fs-12">Aug 01, 2012</span>
                                        </li>
                                        <li>
                                            <i className="fas fa-tag"></i>
                                            <span className="uppercase fs-12">{t("NewsMain.Latest News")}</span>
                                        </li>
                                    </ul>
                                    <p className="mb-20">...</p>
                                    <Link to="/Page13" title={t("NewsMain.Read More")} className="btn btn-primary btn-sm rad-0">{t("NewsMain.Read More")}<i className="fas fa-angle-right pl-10"></i></Link>
                                </div>
                            </div>
                            <div className="blog-post-item col-md-12 mb-20 border-bottom-0 pb-0 mix group-1">
                                <div className="bg-white p-20 box-shadow1 btm-linebg">
                                    <h4 className="mb-10"><Link to="/Page14" title={t("NewsMain.KCodes obtained ISO9001 certificaion")} className="color-primary color-hover-dark">{t("NewsMain.KCodes obtained ISO9001 certificaion")}</Link></h4>
                                    <ul className="blog-post-info list-inline mb-10 pb-0">
                                        <li>
                                            <i className="fas fa-clock-o"></i>
                                            <span className="uppercase fs-12">Oct 01, 2010</span>
                                        </li>
                                        <li>
                                            <i className="fas fa-tag"></i>
                                            <span className="uppercase fs-12">{t("NewsMain.Latest News")}</span>
                                        </li>
                                    </ul>
                                    <p className="mb-20">...</p>
                                    <Link to="/Page14" title={t("NewsMain.Read More")} className="btn btn-primary btn-sm rad-0">{t("NewsMain.Read More")}<i className="fas fa-angle-right pl-10"></i></Link>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="text-center">
                        <ul className="pagination">	
                            <li className="page-item"><Link to="/News" className="page-submit page-link" data-value="1">1</Link></li>	<li className="page-item active"><Link to="/NewsMain1" className="page-link">2</Link></li>
                        </ul>
                    </div>
                </form>
            </div>
</section>





      </>
    )
  };
  
  export default NewsMain1;