import { useTranslation } from 'react-i18next';
import { Link } from "react-router-dom";
const ODMOEM = () => {
    const { t } = useTranslation();
    return (
      <>
        <section className="page-header page-header-xs">
            <div className="container">
                <h1 className="maven">{t("Product.ODM & OEM")}</h1>
                <ol className="breadcrumb">
                    <li><Link to="/" title={t("Product.Home")}>{t("Product.Home")}</Link></li>
                    <li><Link to="/product" title={t("Product.Product")}>{t("Product.Product")}</Link></li>
                    <li><Link to="/product/odmoem" title={t("Product.ODM & OEM")}>{t("Product.ODM & OEM")}</Link></li>
                </ol>
            </div>
        </section>

        <section>
            <div className="container">
                <form name="searchform" id="searchform" method="get">
                    <div id="portfolio" className="portfolio-gutter portfolio-title-over">
                        <div className="text-center">
                            <ul className="nav nav-pills mix-filter mb-30 text-center">
                                <li data-filter="all" className="filter active"><Link to="/product" title="All">{t("Product.All")}</Link></li>
                                <li data-filter="group-3" className="filter"><Link to="/product/wired" title="Wired">{t("Product.Wired")}</Link></li>
                                <li data-filter="group-4" className="filter"><Link to="/product/wireless" title="Wireless">{t("Product.Wireless")}</Link></li>
                                <li data-filter="group-1" className="filter"><Link to="/product/softwaretechnology" title="Software Technology">{t("Product.Software Technology")}</Link></li>
                                <li data-filter="group-2" className="filter"><Link to="/product/odmoem" title={t("Product.ODM & OEM")}>{t("Product.ODM & OEM")}</Link></li>
                            </ul>
                        </div>

                        {/*<div className="row mix-grid">*/}
                        <div className="row">
                            <div className="col-md-4 col-sm-6 mix mb-0 group-2 coreRotate">
                                <div className="item-box">
                                    <figure>
                                        <span className="item-hover">
                                            <span className="overlay dark-5"></span>
                                            <span className="inner">
                                                <a className="ico-rounded lightbox" href="/images/product/pic/customer.jpg" data-plugin-options='{"type":"image"}'>
                                                    <span className="fas fa-plus fs-20 rad-0"></span>
                                                </a>
                                                <a className="ico-rounded" href="/product/customizedproduct">
                                                    <span className="fas fa-link fs-20 rad-0"></span>
                                                </a>
                                            </span>
                                        </span>
                                        <div className="item-box-overlay-title">
                                            <h3><Link to="/product/customizedproduct" title="Customized Product">Customized Product</Link></h3>
                                            <ul className="list-inline categories m-0">
                                                <li><a href="javascript:void(0);" className="uppercase">{t("Product.ODM & OEM")}</a></li>
                                            </ul>
                                        </div>
                                        {/*<div className="owl-carousel buttons-autohide controlls-over m-0" data-plugin-options='{"singleItem": true, "autoPlay": 4000, "navigation": false, "pagination": true, "transitionStyle":"goDown"}'>*/}
                                        <div>
                                            <img className="productanimation" src="/images/product/pic/tcustomer.jpg" width="600" height="399" alt="Customized Product" />
                                        </div>                                     
                                    </figure>
                                </div>
                            </div>

                            <div className="col-md-4 col-sm-6 mix mb-0 group-2 coreRotate">
                                <div className="item-box">
                                    <figure>
                                        <span className="item-hover">
                                            <span className="overlay dark-5"></span>
                                            <span className="inner">
                                                <a className="ico-rounded lightbox" href="/images/product/pic/ePaper.jpg" data-plugin-options='{"type":"image"}'>
                                                    <span className="fas fa-plus fs-20 rad-0"></span>
                                                </a>
                                                <a className="ico-rounded" href="/product/ePaper">
                                                    <span className="fas fa-link fs-20 rad-0"></span>
                                                </a>
                                            </span>
                                        </span>
                                        <div className="item-box-overlay-title">
                                            <h3><Link to="/product/epaper" title="KCodes ePaper">KCodes ePaper</Link></h3>
                                            <ul className="list-inline categories m-0">
                                                <li><a href="javascript:void(0);" className="uppercase">{t("Product.ODM & OEM")}</a></li>
                                            </ul>
                                        </div>
                                        <div>
                                            <img className="productanimation" src="/images/product/pic/tePaper.jpg" width="600" height="399" alt="Customized Product" />
                                        </div>                                     
                                    </figure>
                                </div>
                            </div>
                        </div>


                    </div>
                </form>
            </div>
        </section>
      </>
    )
  };
  
  export default ODMOEM;