import {useEffect} from "react";
import { Link } from "react-router-dom";
import { useTranslation } from 'react-i18next';

const Home = () => {
  	useEffect(() => {
		const prev = document.querySelector('.prev');
		console.log(prev);
		const next = document.querySelector('.next');
		const images = document.querySelector('.carousel').children;
		console.log(document.querySelector('.carousel').children);
		const totalImages = images.length;
		console.log(totalImages);
		let index = 0;

		prev.addEventListener('click', () => {
			nextImage('next');
		})

		next.addEventListener('click', () => {
			nextImage('prev');
		})

		setInterval(function () {nextImage('next');}, 3000);

		function nextImage(direction) {
			if(direction == 'next') {
				index++;
				if(index == totalImages) {
				index = 0;
				}
			} else {
				if(index == 0) {
				index = totalImages - 1;
				} else {
				index--;
				}
			}

			for(let i = 0; i < images.length; i++) {
				images[i].classList.remove('main');
			}
				images[index].classList.add('main');
		}

  	});

  const { t } = useTranslation(); 
  return (
    <>
    <div className="carousel-container">
        <div className="navigation">
			<div className="prev nav-btn"><span style={{fontSize: '30pt'}}><i className="fas fa-chevron-left"></i></span></div>
            <div className="next nav-btn"><span style={{fontSize: '30pt'}}><i className="fas fa-chevron-right"></i></span></div>		
        </div>
        <div className="carousel">
            <div className="item main">
                <img src="images/bg-3.jpg" alt="mountain" />
				<div className="pics">
					<img src="images/banner-item-1.png" alt="banner-item"/>
				</div>
				<div className="yourbeliable">
				<h7><span style={{fontSize: '2vw'}}><strong>&nbsp;YOUR RELIABLE&nbsp;<br />&nbsp;USB SOLUTION PARTNER&nbsp;</strong></span></h7>
				</div>

            </div>
            <div className="item">
                <img src="images/bg-2.jpg" alt="beach" />
				<div className="meetourpatented">
				<h7><span style={{fontSize: '2vw'}}><strong>&nbsp;MEET OUR PATENTED&nbsp;<br />&nbsp;USB OVER IP TECHNOLOGY&nbsp;</strong></span></h7>
				</div>

            </div>
            <div className="item">
                <img src="images/bg-1.jpg" alt="cityscape" />
				<div className="easilyconnect">
				<h7><span style={{fontSize: '2vw'}}><strong>&nbsp;EASILY CONNECT AND&nbsp;<br />&nbsp;SHARE YOUR USB DEVICES&nbsp;</strong></span></h7>
				</div>
            </div>      
        </div>
    </div>

	<section className="bg-light-gray border-bottom-0">
		<div className="container">
			<div className="heading-title heading-dotted">
				<h2 className="uppercase maven bg-light-gray color-primary">{t("Home.THE NEWEST POSTS")}</h2>
			</div>
			<div className="row">
				<div className="blog-post-item col-md-12 mb-20 border-bottom-0 pb-0">
					<div className="bg-white p-20 box-shadow btm-linebg">
						<h4 className="mb-10"><Link to="/Page18" title={t("NewsMain.Our new software technology")} className="color-primary color-hover-dark">{t("NewsMain.Our new software technology")}</Link></h4>
						<ul className="blog-post-info list-inline mb-10 pb-0">
							<li>
								<i className="fas fa-clock-o"></i>
								<span className="uppercase fs-12"> Nov 27, 2023</span>
							</li>
							<li>
								<i className="fas fa-tag"></i>
								<span className="uppercase fs-12">{t("Home.Latest News")}</span>
							</li>
						</ul>
						<p className="mb-20">...</p>
						<Link to="/Page18" title={t("Home.Read More")} className="btn btn-primary btn-sm rad-0">{t("Home.Read More")}<i className="fas fa-angle-right pl-10"></i></Link>
					</div>
				</div>
				<div className="blog-post-item col-md-12 mb-20 border-bottom-0 pb-0">
					<div className="bg-white p-20 box-shadow btm-linebg">
						<h4 className="mb-10"><Link to="/Page17" title={t("NewsMain.KCodes is your best ePaper partner. We are now providing various customized e-paper solutions.")} className="color-primary color-hover-dark">{t("NewsMain.KCodes is your best ePaper partner. We are now providing various customized e-paper solutions.")}</Link></h4>
						<ul className="blog-post-info list-inline mb-10 pb-0">
							<li>
								<i className="fas fa-clock-o"></i>
								<span className="uppercase fs-12"> MAY 30, 2023</span>
							</li>
							<li>
								<i className="fas fa-tag"></i>
								<span className="uppercase fs-12">{t("Home.Latest News")}</span>
							</li>
						</ul>
						<p className="mb-20">...</p>
						<Link to="/Page17" title={t("Home.Read More")} className="btn btn-primary btn-sm rad-0">{t("Home.Read More")}<i className="fas fa-angle-right pl-10"></i></Link>
					</div>
				</div>
				<div className="blog-post-item col-md-12 mb-20 border-bottom-0 pb-0">
					<div className="bg-white p-20 box-shadow btm-linebg">
						<h4 className="mb-10"><Link to="/Page16" title={t("NewsMain.Your best gaming partner! How about our Light sync solution.")} className="color-primary color-hover-dark">{t("NewsMain.Your best gaming partner! How about our Light sync solution.")}</Link></h4>
						<ul className="blog-post-info list-inline mb-10 pb-0">
							<li>
								<i className="fas fa-clock-o"></i>
								<span className="uppercase fs-12"> MAY 31, 2022</span>
							</li>
							<li>
								<i className="fas fa-tag"></i>
								<span className="uppercase fs-12">{t("Home.Latest News")}</span>
							</li>
						</ul>
						<p className="mb-20">...</p>
						<Link to="/Page16" title={t("Home.Read More")} className="btn btn-primary btn-sm rad-0">{t("Home.Read More")}<i className="fas fa-angle-right pl-10"></i></Link>
					</div>
				</div>
			</div>
		</div>
	</section>

    <section className="p-0 border-0">
		<div className="container">
			<div className="heading-title heading-dotted">
				<h2 className="uppercase maven bg-bluegreen">{t("Home.THE NEWEST PRODUCTS")}</h2>
			</div>
			<div id="portfolio" className="portfolio-gutter portfolio-title-over">
				<div className="row">
					<div className="col-md-4 col-sm-6">
						<div className="item-box">
							<figure>
								<span className="item-hover">
									<span className="overlay dark-5"></span>
									<span className="inner">
										<a className="ico-rounded lightbox" href="/images/product/pic/tePaper.jpg" data-plugin-options='{"type":"image"}'>
											<span className="fas fa-plus fs-20 rad-0"></span>
										</a>
										<Link className="ico-rounded" to="/product/ePaper">
											<span className="fas fa-link fs-20 rad-0"></span>
										</Link>
									</span>
								</span>
								<div className="item-box-overlay-title">
									<h3><Link to="/product/ePaper" title="KCodes ePaper">KCodes ePaper</Link></h3>
									<ul className="list-inline categories m-0">
										<li><a href="javascript:void(0)" className="uppercase">{t("Product.ODM & OEM")}</a></li>
									</ul>
								</div>
								<img className="img-fluid" src="/images/product/pic/tePaper.jpg" width="600" height="399" alt="ePaper" />
							</figure>
						</div>
					</div>
					<div className="col-md-4 col-sm-6">
						<div className="item-box">
							<figure>
								<span className="item-hover">
									<span className="overlay dark-5"></span>
									<span className="inner">
										<a className="ico-rounded lightbox" href="/images/product/pic/MT7933IoTModule.jpg" data-plugin-options='{"type":"image"}'>
											<span className="fas fa-plus fs-20 rad-0"></span>
										</a>
										<Link className="ico-rounded" to="/product/MT7933IoTModule">
											<span className="fas fa-link fs-20 rad-0"></span>
										</Link>
									</span>
								</span>
								<div className="item-box-overlay-title">
									<h3><Link to="/product/MT7933IoTModule" title="MT7933 11AX IoT Module">MT7933 11AX IoT Module</Link></h3>
									<ul className="list-inline categories m-0">
										<li><a href="javascript:void(0)" className="uppercase">{t("Home.Wireless")}</a></li>
									</ul>
								</div>
								<img className="img-fluid" src="/images/product/pic/MT7933IoTModule.jpg" width="600" height="399" alt="MT7933IoTModule" />
							</figure>
						</div>
					</div>
				</div>
			</div>
		</div>
	</section>

	<section className="pt-40 pb-50 bg-light-gray">
		<div className="container">
			<div className="heading-title heading-dotted">
				<h2 className="uppercase maven bg-light-gray color-primary">{t("Home.Our Partner")}</h2>
			</div>
			<div className="carousel1-container1">
			<div className="our-partner">
						<div className="itema">
							<a><img className="img-fluid" src="images/a.jpg" alt="Mediatek.Realtek.Qualcomm" /></a>
						</div>
						<div className="itema">
							<a><img className="img-fluid" src="images/b.jpg" alt="cavium.broadcom.freescale" /></a>
						</div>
						<div className="itema">
							<a><img className="img-fluid" src="images/c.jpg" alt="marvell.ST.Texas" /></a>
						</div>
				</div> 
			</div>
		</div>		
	</section>

    </>
  )
};

export default Home;