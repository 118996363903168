import { useTranslation } from 'react-i18next';
import { Link } from "react-router-dom";
const Wireless = () => {
    const { t } = useTranslation();
    return (
      <>
        <section className="page-header page-header-xs">
            <div className="container">
                <h1 className="maven">{t("Product.Wireless")}</h1>
                <ol className="breadcrumb">
                    <li><Link to="/" title={t("Product.Home")}>{t("Product.Home")}</Link></li>
                    <li><Link to="/product" title={t("Product.Product")}>{t("Product.Product")}</Link></li>
                    <li><Link to="/product/wireless" title={t("Product.Wireless")}>{t("Product.Wireless")}</Link></li>
                </ol>
            </div>
        </section>

        <section>
            <div className="container">
                <form name="searchform" id="searchform" method="get">
                    <div id="portfolio" className="portfolio-gutter portfolio-title-over">
                        <div className="text-center">
                            <ul className="nav nav-pills mix-filter mb-30 text-center">
                                <li data-filter="all" className="filter active"><Link to="/product" title={t("Product.All")}>{t("Product.All")}</Link></li>
                                <li data-filter="group-3" className="filter"><Link to="/product/wired" title={t("Product.Wired")}>{t("Product.Wired")}</Link></li>
                                <li data-filter="group-4" className="filter"><Link to="/product/wireless" title={t("Product.Wireless")}>{t("Product.Wireless")}</Link></li>
                                <li data-filter="group-1" className="filter"><Link to="/product/softwaretechnology" title={t("Product.Software Technology")}>{t("Product.Software Technology")}</Link></li>
                                <li data-filter="group-2" className="filter"><Link to="/product/odmoem" title={t("Product.ODM & OEM")}>{t("Product.ODM & OEM")}</Link></li>
                            </ul>
                        </div>
                        {/*<div className="row mix-grid">*/}
                        <div className="row">
                            <div className="col-md-4 col-sm-6 mix mb-0 group-4 fadeInRight">
                                <div className="item-box">
                                    <figure>
                                        <span className="item-hover">
                                            <span className="overlay dark-5"></span>
                                            <span className="inner">
                                                <a className="ico-rounded lightbox" href="/images/product/601n/IMG_1231_1.jpg" data-plugin-options='{"type":"image"}'>
                                                    <span className="fas fa-plus fs-20 rad-0"></span>
                                                </a>
                                                <a className="ico-rounded" href="/product/kc601n">
                                                    <span className="fas fa-link fs-20 rad-0"></span>
                                                </a>
                                            </span>
                                        </span>
                                        <div className="item-box-overlay-title">
                                            <h3><Link to="/product/kc601n" title="KC601n">KC601n</Link></h3>
                                            <ul className="list-inline categories m-0">
                                                <li><a href="javascript:void(0);" className="uppercase">{t("Product.Wireless")}</a></li>
                                            </ul>
                                        </div>
                                        {/*<div className="owl-carousel buttons-autohide controlls-over m-0" data-plugin-options='{"singleItem": true, "autoPlay": 4000, "navigation": false, "pagination": true, "transitionStyle":"goDown"}'>*/}
                                        <div id="carouselIndicators-kc601n" className="carousel" data-bs-ride="carousel">
                                            <div className="carousel-indicators member-3">
                                                <button type="button" data-bs-target="#carouselIndicators-kc601n" data-bs-slide-to="0" className="active" aria-current="true" aria-label="Slide 1" style={{width:'20px', height:'6px'}}></button>
                                                <button type="button" data-bs-target="#carouselIndicators-kc601n" data-bs-slide-to="1" aria-label="Slide 2" style={{width:'20px', height:'6px'}}></button>
                                                <button type="button" data-bs-target="#carouselIndicators-kc601n" data-bs-slide-to="2" aria-label="Slide 3" style={{width:'20px', height:'6px'}}></button>
                                            </div>

                                            <div className="carousel-inner">
                                                <div className="carousel-item active">
                                                    <img className="productanimation" src="/images/product/601n/tIMG_1231_1.jpg" width="600" height="399" alt="KC601n" />
                                                </div>
                                                <div className="carousel-item">
                                                    <img className="productanimation" src="/images/product/601n/tIMG_1227_1.jpg" width="600" height="399" alt="KC601n" />
                                                </div>
                                                <div className="carousel-item">
                                                    <img className="productanimation" src="/images/product/601n/tIMG_1235_1.jpg" width="600" height="399" alt="KC601n" />
                                                </div>
                                            </div>
                                        </div>
                                    </figure>
                                </div>
                            </div>
                            <div className="col-md-4 col-sm-6 mix mb-0 group-4 fadeInLeft">
                                <div className="item-box">
                                    <figure>
                                        <span className="item-hover">
                                            <span className="overlay dark-5"></span>
                                            <span className="inner">
                                                <a className="ico-rounded lightbox" href="/images/product/612/IMG_1247_1.jpg" data-plugin-options='{"type":"image"}'>
                                                    <span className="fas fa-plus fs-20 rad-0"></span>
                                                </a>
                                                <a className="ico-rounded" href="/product/kc612ac">
                                                    <span className="fas fa-link fs-20 rad-0"></span>
                                                </a>
                                            </span>
                                        </span>
                                        <div className="item-box-overlay-title">
                                            <h3><Link to="/product/kc612ac" title="KC612ac">KC612ac</Link></h3>
                                            <ul className="list-inline categories m-0">
                                                <li><a href="javascript:void(0);" className="uppercase">{t("Product.Wireless")}</a></li>
                                            </ul>
                                        </div>
                                        {/*<div className="owl-carousel buttons-autohide controlls-over m-0" data-plugin-options='{"singleItem": true, "autoPlay": 4000, "navigation": false, "pagination": true, "transitionStyle":"goDown"}'>*/}
                                        <div id="carouselIndicators-kc612ac" className="carousel" data-bs-ride="carousel">
                                            <div className="carousel-indicators member-4">
                                                <button type="button" data-bs-target="#carouselIndicators-kc612ac" data-bs-slide-to="0" className="active" aria-current="true" aria-label="Slide 1" style={{width:'20px', height:'6px'}}></button>
                                                <button type="button" data-bs-target="#carouselIndicators-kc612ac" data-bs-slide-to="1" aria-label="Slide 2" style={{width:'20px', height:'6px'}}></button>
                                                <button type="button" data-bs-target="#carouselIndicators-kc612ac" data-bs-slide-to="2" aria-label="Slide 3" style={{width:'20px', height:'6px'}}></button>
                                                <button type="button" data-bs-target="#carouselIndicators-kc612ac" data-bs-slide-to="3" aria-label="Slide 4" style={{width:'20px', height:'6px'}}></button>
                                            </div>

                                            <div className="carousel-inner">
                                                <div className="carousel-item active">
                                                <img className="productanimation" src="/images/product/612/tIMG_1247_1.jpg" width="600" height="399" alt="KC612ac" />
                                                </div>
                                                <div className="carousel-item">
                                                    <img className="productanimation" src="/images/product/612/tIMG_1250_1.jpg" width="600" height="399" alt="KC612ac" />
                                                </div>
                                                <div className="carousel-item">
                                                    <img className="productanimation" src="/images/product/612/tIMG_1245_1.jpg" width="600" height="399" alt="KC612ac" />
                                                </div>
                                                <div className="carousel-item">
                                                    <img className="productanimation" src="/images/product/612/tIMG_1249_1.jpg" width="600" height="399" alt="KC612ac" />
                                                </div>
                                            </div>
                                        </div>
                                    </figure>
                                </div>
                            </div>
                            <div className="col-md-4 col-sm-6 mix mb-0 group-4 fadeInRight">
                                <div className="item-box">
                                    <figure>
                                        <span className="item-hover">
                                            <span className="overlay dark-5"></span>
                                            <span className="inner">
                                                <a className="ico-rounded lightbox" href="/images/product/612/wifi-bridge.png" data-plugin-options='{"type":"image"}'>
                                                    <span className="fas fa-plus fs-20 rad-0"></span>
                                                </a>
                                                <a className="ico-rounded" href="/product/wb112ac">
                                                    <span className="fas fa-link fs-20 rad-0"></span>
                                                </a>
                                            </span>
                                        </span>
                                        <div className="item-box-overlay-title">
                                            <h3><Link to="/product/wb112ac" title="WB112ac">WB112ac</Link></h3>
                                            <ul className="list-inline categories m-0">
                                                <li><a href="javascript:void(0);" className="uppercase">{t("Product.Wireless")}</a></li>
                                            </ul>
                                        </div>
                                        {/*<div className="owl-carousel buttons-autohide controlls-over m-0" data-plugin-options='{"singleItem": true, "autoPlay": 4000, "navigation": false, "pagination": true, "transitionStyle":"goDown"}'>*/}
                                        <div id="carouselIndicators-wb112ac" className="carousel" data-bs-ride="carousel">
                                            <div className="carousel-indicators member-3">
                                                <button type="button" data-bs-target="#carouselIndicators-wb112ac" data-bs-slide-to="0" className="active" aria-current="true" aria-label="Slide 1" style={{width:'20px', height:'6px'}}></button>
                                                <button type="button" data-bs-target="#carouselIndicators-wb112ac" data-bs-slide-to="1" aria-label="Slide 2" style={{width:'20px', height:'6px'}}></button>
                                                <button type="button" data-bs-target="#carouselIndicators-wb112ac" data-bs-slide-to="2" aria-label="Slide 3" style={{width:'20px', height:'6px'}}></button>
                                            </div>

                                            <div className="carousel-inner">
                                                <div className="carousel-item active">
                                                    <img className="productanimation" src="/images/product/612/twifi-bridge.png" width="600" height="399" alt="WB112ac" />
                                                </div>
                                                <div className="carousel-item">
                                                    <img className="productanimation" src="/images/product/612/tIMG_1247_2.png" width="600" height="399" alt="WB112ac" />
                                                </div>
                                                <div className="carousel-item">
                                                    <img className="productanimation" src="/images/product/612/tIMG_1245_3.jpg" width="600" height="399" alt="WB112ac" />
                                                </div>
                                            </div>
                                        </div>
                                    </figure>
                                </div>
                            </div>
                            <div className="col-md-4 col-sm-6 mix mb-0 group-4 fadeInUpLeft">
                                <div className="item-box">
                                    <figure>
                                        <span className="item-hover">
                                            <span className="overlay dark-5"></span>
                                            <span className="inner">
                                                <a className="ico-rounded lightbox" href="/images/product/622n/IMG_1247_12-01.jpg" data-plugin-options='{"type":"image"}'>
                                                    <span className="fas fa-plus fs-20 rad-0"></span>
                                                </a>
                                                <a className="ico-rounded" href="/product/kc622n">
                                                    <span className="fas fa-link fs-20 rad-0"></span>
                                                </a>
                                            </span>
                                        </span>
                                        <div className="item-box-overlay-title">
                                            <h3><Link to="/product/kc622n" title="KC622n">KC622n</Link></h3>
                                            <ul className="list-inline categories m-0">
                                                <li><a href="javascript:void(0);" className="uppercase">{t("Product.Wireless")}</a></li>
                                            </ul>
                                        </div>
                                        {/*<div className="owl-carousel buttons-autohide controlls-over m-0" data-plugin-options='{"singleItem": true, "autoPlay": 4000, "navigation": false, "pagination": true, "transitionStyle":"goDown"}'>*/}
                                        <div id="carouselIndicators-kc622n" className="carousel" data-bs-ride="carousel">
                                            <div className="carousel-indicators member-2">
                                                <button type="button" data-bs-target="#carouselIndicators-kc622n" data-bs-slide-to="0" className="active" aria-current="true" aria-label="Slide 1" style={{width:'20px', height:'6px'}}></button>
                                                <button type="button" data-bs-target="#carouselIndicators-kc622n" data-bs-slide-to="1" aria-label="Slide 2" style={{width:'20px', height:'6px'}}></button>
                                            </div>

                                            <div className="carousel-inner">
                                                <div className="carousel-item active">
                                                    <img className="productanimation" src="/images/product/622n/tIMG_1247_12-01.jpg" width="600" height="399" alt="KC622n" />
                                                </div>
                                                <div className="carousel-item">
                                                    <img className="productanimation" src="/images/product/622n/tIMG_1249_1222-01.jpg" width="600" height="399" alt="KC622n" />
                                                </div>
                                            </div>
                                        </div>
                                    </figure>
                                </div>
                            </div>

                            <div className="col-md-4 col-sm-6 mix mb-0 group-4 fadeInUpLeft">
                                <div className="item-box">
                                    <figure>
                                        <span className="item-hover">
                                            <span className="overlay dark-5"></span>
                                            <span className="inner">
                                                <a className="ico-rounded lightbox" href="/images/product/pic/MT7933IoTModule.jpg" data-plugin-options='{"type":"image"}'>
                                                    <span className="fas fa-plus fs-20 rad-0"></span>
                                                </a>
                                                <a className="ico-rounded" href="/product/MT7933IoTModule">
                                                    <span className="fas fa-link fs-20 rad-0"></span>
                                                </a>
                                            </span>
                                        </span>
                                        <div className="item-box-overlay-title">
                                            <h3><Link to="/product/MT7933IoTModule" title="MT7933 11AX IoT Module">MT7933 11AX IoT Module</Link></h3>
                                            <ul className="list-inline categories m-0">
                                                <li><a href="javascript:void(0);" className="uppercase">{t("Product.Wireless")}</a></li>
                                            </ul>
                                        </div>
                                        <div id="carouselIndicators-MT7933IoTModule" className="carousel" data-bs-ride="carousel">
                                            <div className="carousel-indicators">
                                                <button type="button" data-bs-target="#carouselIndicators-MT7933IoTModule" data-bs-slide-to="0" className="active" aria-current="true" aria-label="Slide 1" style={{width:'20px', height:'6px'}}></button>
                                                <button type="button" data-bs-target="#carouselIndicators-MT7933IoTModule" data-bs-slide-to="1" aria-label="Slide 2" style={{width:'20px', height:'6px'}}></button>
                                            </div>

                                            <div className="carousel-inner">
                                                <div className="carousel-item active">
                                                <img className="productanimation" src="/images/product/pic/MT7933IoTModule.jpg" width="600" height="399" alt="MT7933IoTModule" />
                                                </div>
                                                <div className="carousel-item">
                                                    <img className="productanimation" src="/images/product/pic/MT7933IoTModule.jpg" width="600" height="399" alt="MT7933IoTModule" />
                                                </div>
                                            </div>
                                        </div>
                                    </figure>
                                </div>
                            </div>


                        </div>
                    </div>
                </form>
            </div>
        </section>
      </>
    )
  };
  
  export default Wireless;