import { useTranslation } from 'react-i18next';
import { Link } from "react-router-dom";
import React from 'react';

const Page14 = () => {
	const { t } = useTranslation();
    return(
    
      <> 		
   	    <section className="page-header page-header-xs">
            <div className="container">
                <h1 className="maven">{t("Page.KCodes obtained ISO9001 certificaion")}</h1>

            </div>
        </section>

        <section>
            <div className="container">
                <div className="row">
                    <div className="col-lg-12">
                        <h2 className="h3 font-weight-bold blog-post-title">{t("Page.KCodes obtained ISO9001 certificaion")}</h2>
                        <ul className="blog-post-info list-inline">
                            <li>
                                <i className="fas fa-clock-o"></i>
                                <span className="uppercase fs-12">Oct 01, 2010</span>
                            </li>
                            <li>
                                <i className="fas fa-tag"></i>
                                <span className="uppercase fs-12">{t("Page.Latest News")}</span>
                            </li>
                        </ul>
                    </div>
                </div>
                <div className="row">
                    <div className="col-lg-12">
                        <p>{t("Page.KCodes formally obtained international ISO 9001 certification.")}<br />
                        {t("Page.In addition to the quality management system, it also focuses on the risk controls.")}<br />
                        {t("Page.With the ISO 9001 certification, our quality management has been upgraded to the next level, and the after-sales service quality management is fully promoted.")}<br />
                        {t("Page.We look forward to developing and manufacturing more good products for customers in the future and providing better services!")}</p>
                    </div>
                </div>
                <div className="divider my-2"></div>
                <div className="news-in-foot">
                    <div className="float-right">
                        <a href="javascript:history.back();" class="btn btn-sm btn-dark rad-0 btn-black uppercase" title={t("Page.Back")}><i class="fa fa-reply"></i>{t("Page.Back")}</a>
                    </div>
                </div>
            </div>
        </section>


      </>
    )
  };


  export default Page14;